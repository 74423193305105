import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { appName } from 'consts'
import Header from 'shared/containers/Header'
import getCurrentPage from 'shared/utils/getCurrentPage'
import getPageByPath from 'shared/utils/getPageByPath'
import Page from 'shared/types/Page'

interface WrapperProps {
  headerInsert?: React.FunctionComponent | React.ReactElement | false
  actionInsert?: React.FunctionComponent | React.ReactElement | false
  footerContent?: React.FunctionComponent | React.ReactElement | string | false
  showCardHeader?: boolean
}

const Menu: React.FunctionComponent<WrapperProps> = (props) => {
  const { children } = props
  const [pageTitle, setPageTitle] = useState('Loading')
  const [pageTitleFull, setPageTitleFull] = useState('Loading')
  const [addLink, setAddLink] = useState<string | undefined>(undefined)
  const [addLinkRoles, setAddLinkRoles] = useState<String[] | undefined>(undefined)
  let curLoc = useLocation()
  // const mainPanel = useRef();

  useEffect(() => {
    // set page title
    const curPage = getCurrentPage(curLoc)

    if (curPage && curPage.name) {
      let pageTitle = curPage.pageName ? curPage.pageName : curPage.name
      setPageTitle(pageTitle)

      if (typeof curPage.parentPath !== 'undefined') {
        const parentPage = getPageByPath(curPage.parentPath)
        if (parentPage) {
          pageTitle = parentPage.name + ' / ' + pageTitle
        }
      }

      setPageTitleFull(pageTitle)

      if (curPage.addLink !== undefined) {
        setAddLink(curPage.addLink)

        const addPage: Page | null = getPageByPath(curPage.addLink)
        if (addPage?.roles !== undefined) {
          setAddLinkRoles(addPage.roles)
        }
      }

      const title = curPage.name + ' | ' + appName
      document.title = title
    }

    // // scrollbar
    // if (navigator.platform.indexOf("Win") > -1) {
    //     var ps = new PerfectScrollbar(mainPanel.current);
    //     document.body.classList.toggle("perfect-scrollbar-on");
    // }

    // return function cleanup() {
    //     if ((navigator.platform.indexOf("Win") > -1)  && (typeof ps !== 'undefined')) {
    //         ps.destroy();
    //         document.body.classList.toggle("perfect-scrollbar-on");
    //     }
    // };
  }, [curLoc])

  return (
    <main className='main-panel'>
      <Header
        title={pageTitle}
        addLink={addLink}
        addLinkRoles={addLinkRoles}
        headerInsert={props.headerInsert}
      />

      <div className='content'>
        <Row className="container">
          <Col md='12'>
            <Card>
              {props.showCardHeader && (
                <CardHeader className='main'>
                  <h1 className='trolley-header'>{pageTitle}</h1>
                  {props.actionInsert ? (
                    <div className='action-insert'>{props.actionInsert}</div>
                  ) : (
                    ''
                  )}
                </CardHeader>
              )}
              <CardBody>{children}</CardBody>
            </Card>
          </Col>
        </Row>
        {props.footerContent}
      </div>
    </main>
  )
}

export default Menu

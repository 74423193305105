import { useEffect } from 'react'
import Store from 'redux/Store'
import { getAllCustomData } from 'shared/components/App/Auth'
import { setCustomData } from 'shared/redux/actions'
import processEntityChange from '../utils/processEntityChange'
import { setEntityName } from 'redux/actions'

interface useInitCentersProps {
  currentEntity: any
  initialRender: boolean
  dataLoaded: boolean
  setInitialRender: Function
  handleEntityChanged?: Function
}

const useEntityChange = (props: useInitCentersProps) => {
  useEffect(() => {
    if (!props.initialRender && props.dataLoaded) {
      processEntityChange({
        entity_id: props.currentEntity?.id,
        setShowOverlay: (data) => {},
      }).then((data: any) => {
        let allCustomData = getAllCustomData()
        allCustomData.entity = props.currentEntity
        Store.dispatch(setCustomData(allCustomData))
        Store.dispatch(setEntityName(allCustomData.entity.name))

        if (props.handleEntityChanged !== undefined) {
          props.handleEntityChanged(props.currentEntity)
        }

        Store.dispatch(setEntityName(props.currentEntity.name))
      })
    } else {
      props.setInitialRender(false)
    }
  }, [props.currentEntity])
}

export default useEntityChange

import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

interface SelectWrapperProps {
  control: any
  options: any
  name: string
  placeholder?: string
  defaultValue?: any
  optionLabel: string
  optionValue: string
  onChange?: Function
  isClearable?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  selectConfig?: any
}

const SelectWrapper: React.FunctionComponent<SelectWrapperProps> = (props: SelectWrapperProps) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <Select
          {...field}
          {...props.selectConfig}
          isDisabled={props.isDisabled ?? false}
          placeholder={props.placeholder ?? 'Select...'}
          defaultValue={props.defaultValue ?? props.options[0]}
          getOptionLabel={(option: any) => option[props.optionLabel]}
          getOptionValue={(option: any) => option[props.optionValue]}
          options={props.options}
          isMulti={props.isMulti ?? false}
          isClearable={props.isClearable ?? true}
          onChange={(option: any) => {
            field.onChange(option)
            if (props.onChange !== undefined) {
              props.onChange(option)
            }
          }}
        />
      )}
    />
  )
}

export default SelectWrapper

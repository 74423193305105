interface confirmDeleteProps {
    e: any;
    id: number;
    setDeleteEntryId: Function;
    setShowModal: Function;
}

const confirmDelete = (props: confirmDeleteProps) => {
    props.e.preventDefault()
    props.setDeleteEntryId(props.id)
    props.setShowModal(true)
}

export default confirmDelete
import { AxiosError } from 'axios'
import { defaultAxiosContentType } from 'consts'
import { getToken } from 'shared/components/App/Auth'
import instance from './axiosBase'

const defaultContentType = defaultAxiosContentType

const axiosPost = (
  url: string,
  data: any = {},
  catchFunc: boolean | Function = false,
  contentType: boolean | string = false,
  requestOptions: object = {},
) => {
  if (!contentType) {
    contentType = defaultContentType
  }

  const token = getToken()

  const response = instance
    .post(url, data, {
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`,
      },
      ...requestOptions,
    })
    .catch((err: AxiosError) => {
      if (typeof catchFunc === 'function') {
        return catchFunc(err)
      }
    })

  return response
}

const axiosPut = (
  url: string,
  data: any = {},
  catchFunc: boolean | Function = false,
  contentType: boolean | string = false,
) => {
  if (!contentType) {
    contentType = defaultContentType
  }

  const token = getToken()

  const response = instance
    .put(url, data, {
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err: AxiosError) => {
      if (typeof catchFunc === 'function') {
        return catchFunc(err)
      }
    })

  return response
}

const axiosDelete = (url: string, data: any = {}, catchFunc: boolean | Function = false) => {
  const token = getToken()

  const response = instance
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((err: AxiosError) => {
      if (typeof catchFunc === 'function') {
        return catchFunc(err)
      }
    })

  return response
}

const axiosGet = (
  url: string,
  data: any = {},
  catchFunc: boolean | Function = false,
  requestOptions: object = {},
) => {
  const token = getToken()

  const response = instance
    .get(url, {
      params: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...requestOptions,
    })
    .catch((err: AxiosError) => {
      if (typeof catchFunc === 'function') {
        return catchFunc(err)
      }
    })

  return response
}

export { axiosPost, axiosPut, axiosGet, axiosDelete }

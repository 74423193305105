import { AnyAction } from 'redux'

interface CustomDataState {
  customData: object | null
}

const noCustomData: CustomDataState = {
  customData: null,
}

const customData = (state = noCustomData, action: AnyAction) => {
  switch (action.type) {
    case 'SET_CUSTOM_DATA':
      const newState = {
        customData: action.customData,
      }

      return {
        ...state,
        ...newState,
      }

    default:
      return state
  }
}

export default customData

import * as React from 'react'
import MenuItem from 'shared/components/MenuItem'
import Pages from 'components/AppRouter/Pages'
import { Nav, Navbar as Bnavbar} from "react-bootstrap"
import MenuItemLogout from 'shared/components/MenuItemLogout'
import hasAnyRole from 'shared/utils/hasAnyRole'

interface NavbarProps {
    title: string
}

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {

    return (
        <Bnavbar expand="disable" className="d-none">
            <Bnavbar.Toggle aria-controls="basic-navbar-nav" />
            <Bnavbar.Collapse id="basic-navbar-nav">
                <Nav>
                    {Pages.map((props: any, key: number | string) => {
                        if (props.showInMenu && props.parentPath === undefined && (props.roles === undefined || hasAnyRole(props.roles))) {
                            return (
                                <MenuItem key={props.name} label={props.name} link={props.path} icon={props.icon} />
                            );
                        }
                    })}
                    <MenuItemLogout />
                </Nav>
            </Bnavbar.Collapse>
        </Bnavbar>
    );
}

export default Navbar
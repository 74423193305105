import React from 'react'
import './Main.css';
import ContentWrapper from 'shared/components/ContentWrapper';
import Sidebar from 'shared/components/Sidebar';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

interface MainLayoutProps {
    showOverlay?: boolean
    headerInsert?: React.FunctionComponent | React.ReactElement | false 
    actionInsert?: React.FunctionComponent | React.ReactElement | false
    brandContent?: React.FunctionComponent | React.ReactElement | string | false
    footerContent?: React.FunctionComponent | React.ReactElement | string | false
    showCardHeader?: boolean
}

const MainLayout: React.FunctionComponent<MainLayoutProps> = (props) => {
    
    const children:any = React.Children.only(props.children)

    document.body.classList.add('sidebar-pinned');

    return (
        <div className="wrapper">
            
            <ContentWrapper 
                headerInsert={props.headerInsert} 
                actionInsert={props.actionInsert} 
                footerContent={props.footerContent} 
                showCardHeader={props.showCardHeader}
            >
                {React.cloneElement(children)}
                {props.showOverlay && <div className="main-overlay">
                    <img src="/images/loading.svg" alt="loading" />
                </div>}
            </ContentWrapper>
        </div>
    );
}

export default MainLayout
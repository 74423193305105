import { axiosGet } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'

const loadRecord = async (token: string) => {
  const response = await axiosGet(
    apiUrls.actionResetPassword + '?token=' + token,
    null,
    (err: AxiosError) => {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: () => {}, message: message })
    },
  )
  return response
}

export default loadRecord

import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import Page from 'shared/types/Page'
import getPages from 'utils/getPages'
import getCurrentPage from 'shared/utils/getCurrentPage'
import getPageByPath from 'shared/utils/getPageByPath'
import useInit from './hooks/useInit'
import MenuItemLogout from 'shared/components/MenuItemLogout'

interface MenuItemDropdownProps {
    label: string
    link: string
    icon?: any
    isActive?: boolean
    pages?: Page[]
    showLogOut?: boolean
}

const MenuItemDropdown: React.FunctionComponent<MenuItemDropdownProps> = (props) => {

    let curLoc = useLocation()
    const curPage = getCurrentPage(curLoc)
    let activeClass: string = ''

    const [subMenuPages, setSubMenuPages] = useState<any>([])

    useInit({
        setSubMenuPages,
        curPage,
        curLink: props.link
    })

    if (curPage?.parentPath !== undefined) {

        const parentPage = getPageByPath(curPage.parentPath)
        activeClass =
            (props.link === curPage.parentPath ||
                (parentPage?.parentPath !== undefined &&
                    parentPage.parentPath === props.link))
                ? " active"
                : "";
    }

    return (
        <li className="menu-item">
            <NavDropdown title={props.label} id="basic-nav-dropdown">
                {subMenuPages.length && subMenuPages.map((pageProps: any) => {
                    return (
                        <NavDropdown.Item>
                            <NavLink
                                to={pageProps.path}
                                className={"nav-link" + activeClass}
                            >
                                {pageProps.name}
                            </NavLink>
                        </NavDropdown.Item>
                    )
                })}
                {props.showLogOut && <NavDropdown.Item>
                    <MenuItemLogout />
                </NavDropdown.Item>}
            </NavDropdown>
        </li>
    );
}

export default MenuItemDropdown
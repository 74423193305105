import * as React from 'react'

interface FooterProps {
  wrapperDivAttributes?: object
}

const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  const defaultWrapperDivAttributes = { md: 12, className: 'mt-3 mb-5' }

  return (
    <div {...defaultWrapperDivAttributes} {...props.wrapperDivAttributes}>
      <div className='text-center small text-secondary'>
        Copyright &#169; {new Date().getFullYear()} Royalty Solutions Corp. All Rights Reserved
      </div>
    </div>
  )
}

export default Footer

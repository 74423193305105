import { connect } from 'react-redux';
import Header from 'shared/components/Header';

const mapStateToProps = (state: any) => {
    return {
        message: state.message
    };
}

export default connect(
    mapStateToProps
)(Header)
import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import ActionButtons from 'shared/components/ActionButtons'

interface getRowsProps {
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  synchronizeEntity: any
  setSyncCount: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.entities)

  data.forEach((row: any, index: number, dataArray: any) => {
    const synchronizeEntityFunction = () => {
      return props.synchronizeEntity({
        id: row.id,
        setShowOverlay: props.setShowOverlay,
        setSyncCount: props.setSyncCount,
      })
    }

    dataArray[index].action = (
      <ActionButtons
        buttons={[
          {
            type: 'u',
            url: '/edit-entity/' + row.id,
          },
          {
            type: 'sync',
            id: row.id,
            function: synchronizeEntityFunction,
          },
        ]}
      />
    )
  })

  return data
}

export default getRows

import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import { axiosGet } from 'shared/api/axios'

interface getRowsReturnType {
  entities: Array<any>
  selectedEntity: any | null
}

const getRows = async (): Promise<getRowsReturnType> => {
  const entities = await getAll(apiUrls.entities)
  const selectedEntity = await axiosGet(apiUrls.entities + '/' + apiUrls.actionGetSelectedEntity)

  return {
    entities: entities,
    selectedEntity: selectedEntity,
  } as getRowsReturnType
}

export default getRows

import { useState } from 'react'
import Select, { MenuListProps } from 'react-select'
import useInit from './hooks/useInit'
import getRows from './utils/getRows'
import useEntityChange from './hooks/useEntityChange'
import { selectClassNames } from 'shared/assets/conf'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

interface HeaderEntitySelectProps {
  setShowOverlay?: Function
  handleEntityChanged?: Function
  entityName: any
}

const HeaderEntitySelect: React.FunctionComponent<HeaderEntitySelectProps> = (
  props: HeaderEntitySelectProps,
) => {
  const [rows, setRows] = useState<any>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [initialRender, setInitialRender] = useState<boolean>(true)
  const [currentEntity, setCurrentEntity] = useState<{} | null>(null)

  useInit({
    getRows,
    setRows,
    setCurrentEntity,
    setDataLoaded,
    entityName: props.entityName.name,
  })

  useEntityChange({
    currentEntity,
    initialRender,
    dataLoaded,
    setInitialRender,
    handleEntityChanged: props.handleEntityChanged,
  })

  const handleEntityChange = (value) => {
    if (currentEntity !== value) {
      if (props.setShowOverlay !== undefined) {
        props.setShowOverlay(true)
      }
      setCurrentEntity(value)
    }
  }

  if (currentEntity === null) {
    handleEntityChange(rows[0])
  }

  const MenuList = (props: MenuListProps<any, false, any>) => {
    return (
      <div>
        <PerfectScrollbar style={{ background: 'transparent' }}>{props.children}</PerfectScrollbar>
      </div>
    )
  }

  return (
    <>
      {dataLoaded && (
        <>
          <label className="showing-text">Showing</label>
          <Select
            classNames={selectClassNames}
            components={{ MenuList }}
            options={rows}
            defaultValue={currentEntity}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable={false}
            onChange={(value) => handleEntityChange(value)}
          />
        </>
      )}
      {!dataLoaded && (
        <>
          <label className="showing-text">Showing</label>
          <Select
            classNames={selectClassNames}
            options={[
              {
                value: 0,
                label: props.entityName.name,
              },
            ]}
            defaultValue={{
              value: 0,
              label: props.entityName.name,
            }}
          />
        </>
      )}
    </>
  )
}

export default HeaderEntitySelect

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row, Tooltip, OverlayTrigger, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resAddUser from './resolvers/resAddUser'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import * as Icon from 'react-bootstrap-icons'
import { togglePasswordInput, generateRandomPassword } from 'utils/passwordUtils'
import getBrandContent from 'utils/getBrandContent'
import BackButton from 'shared/components/BackButton'
import Footer from 'shared/components/Footer'

const AddUser: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const submitRef = React.createRef<HTMLButtonElement>()

  const [passwordInputType, setPasswordInputType] = useState<string>('password')

  const { id } = useParams()

  const navigate = useNavigate()

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resAddUser,
  })

  useInit({
    getValues,
    id,
    reset,
    setDataLoaded,
    setShowOverlay,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      headerInsert={<BackButton url='/administrators' text='All Administrators' />}
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            id,
            name: 'Administrator',
            setError: setError,
            successNav: '/administrators',
          }),
        )}
        id='main-form'
      >
        <InputWrapper label='First Name' name='firstname' errors={errors}>
          <Form.Control type='text' {...register('firstname')} />
        </InputWrapper>

        <InputWrapper label='Last Name' name='lastname' errors={errors}>
          <Form.Control type='text' {...register('lastname')} />
        </InputWrapper>

        {!id && (
          <InputWrapper label='Password' name='password_api' errors={errors}>
            <InputGroup className='mb-3'>
              <Form.Control type={passwordInputType} {...register('password_api')} />
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Generate Password</Tooltip>}
              >
                <Button
                  variant='primary'
                  onClick={() => setValue('password_api', generateRandomPassword())}
                >
                  <Icon.Key title='Generate random password' />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    {passwordInputType === 'password' ? 'Show password' : 'Hide password'}
                  </Tooltip>
                }
              >
                <Button
                  variant='warning'
                  onClick={() => setPasswordInputType(togglePasswordInput(passwordInputType))}
                >
                  {passwordInputType === 'password' ? <Icon.Unlock /> : <Icon.Lock />}
                </Button>
              </OverlayTrigger>
            </InputGroup>
          </InputWrapper>
        )}

        <InputWrapper label='Email' name='email' errors={errors}>
          <Form.Control type='email' {...register('email')} />
        </InputWrapper>

        <Button type='submit' name='save' ref={submitRef} className='d-none'></Button>
      </Form>
    </Layout>
  )
}

export default AddUser

import { axiosGet } from 'shared/api/axios'
import { apiUrls } from 'consts'

interface displayInstructionsProps {
  setShowOverlay: Function
}

const displayInstructions = async (props: displayInstructionsProps) => {
  let response: any = false

  response = await axiosGet(
    apiUrls.entities + '/' + apiUrls.actionInstructions,
    {},
    props.setShowOverlay,
    {
      responseType: 'blob',
    },
  ).then(async (response) => {
    if (response === undefined) {
      return
    }
    const retText: any = await response?.data?.text()
    if (response.status === 200) {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'New Entity Instructions.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
    return response
  })

  return response
}

export default displayInstructions

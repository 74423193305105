import Layout from 'shared/layouts/Main'
import React, { useState } from 'react'
import { Button, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { Badge, Card, CardBody, CardFooter, CardHeader, Col } from 'reactstrap'
import * as Icon from 'react-bootstrap-icons'
import { NavLink, useNavigate } from 'react-router-dom'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import hasAnyRole from 'shared/utils/hasAnyRole'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import { apiUrls, roles, tableColumnWidths } from 'consts'
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import getRows from './utils/getRows'
import useInit from './hooks/useInit'
import DeleteModal from 'shared/components/DeleteModal'
import { IonIcon } from '@ionic/react'
import { helpCircleOutline } from 'ionicons/icons'
import displayInstructions from './utils/displayInstructions'
import synchronizeEntity from './utils/synchronizeEntity'
import { formatDateTime, stringToDate } from 'utils/dateUtils'

const PaymentDetails: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [rows, setRows] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)
  const [currentEntity, setCurrentEntity] = useState<{} | null>({})
  const [syncCount, setSyncCount] = useState<number>(0)

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'id', title: '#' },
    { name: 'name', title: 'Name' },
    { name: 'subdomain', title: 'Subdomain' },
    { name: 'code', title: 'Code' },
    {
      name: 'sync_status',
      title: 'Sync Status',
      getCellValue: (row) => {
        if (row.last_sync_error?.length > 0) {
          return (
            <OverlayTrigger
              placement='right'
              delay={{ show: 100, hide: 100 }}
              overlay={<Tooltip>{row.last_sync_error}</Tooltip>}
            >
              <span style={{ cursor: 'help' }}>{row.sync_status}</span>
            </OverlayTrigger>
          )
        } else {
          return row.sync_status
        }
      },
    },
    {
      name: 'last_sync_date',
      title: 'Last Sync Date',
      getCellValue: (row) => {
        return formatDateTime(stringToDate(row.last_sync_date))
      },
    },
    { name: 'action', title: ' ', encodeHtml: false },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'id', width: tableColumnWidths.id },
    { columnName: 'name', width: 'auto' },
    { columnName: 'subdomain', width: 'auto' },
    { columnName: 'code', width: 'auto' },
    { columnName: 'sync_status', width: 'auto' },
    { columnName: 'last_sync_date', width: 'auto' },
    { columnName: 'action', width: tableColumnWidths.action },
  ]

  useInit({
    getRows,
    synchronizeEntity,
    setRows,
    setDeleteEntryId,
    setShowModal,
    setShowOverlay,
    currentEntity,
    syncCount,
    setSyncCount,
  })

  const SearchPanelInputComponent = (props) => {
    const insert: any = (
      <Button
        onClick={(e) => {
          e.preventDefault()
          displayInstructions({
            setShowOverlay,
          })
        }}
        variant='secondary'
        className=''
      >
        <IonIcon
          icon={helpCircleOutline}
          className='ionicon'
          style={{ fontSize: '16px', marginBottom: '-3px' }}
        />
        Instructions
      </Button>
    )

    return SearchPanelInput({
      insert: insert,
      inputProps: props,
      addUrl: '/add-entity',
      addText: 'Add',
    })
  }

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='small min-mobile-width with-action' />
  )

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout footerContent={<Footer />} brandContent={getBrandContent()} showOverlay={showOverlay}>
      <>
        <Grid rows={rows} columns={columns}>
          <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
          <SearchState defaultValue='' />
          <IntegratedFiltering />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <Toolbar />
          <SearchPanel inputComponent={SearchPanelInputComponent} />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
        </Grid>

        <DeleteModal
          url={apiUrls.entities}
          showModal={showModal}
          setShowModal={setShowModal}
          deleteEntryId={deleteEntryId}
          setDeleteEntryId={setDeleteEntryId}
          entityName='Entity'
          getRows={getRows}
          setRows={setRows}
        />
      </>
    </Layout>
  )
}

export default PaymentDetails

import React from 'react'
import { NavLink } from 'react-router-dom'
import { IonIcon } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

interface BackButtonProps {
    text: string
    url: string
}


const BackButton: React.FunctionComponent<BackButtonProps> = (props: BackButtonProps) => {

    return (
        <NavLink to={props.url} className="btn btn-secondary">
          <IonIcon icon={arrowBackOutline} className='ionicon' style={{ fontSize: '16px', verticalAlign: 'bottom' }} />
          {props.text}
        </NavLink>
    )
}


export default BackButton
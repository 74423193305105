import { defaultPageSize } from 'consts'
import { AnyAction } from 'redux'

export interface SettingsStateProps {
  pageSize: number
}

const defaultSettings: SettingsStateProps = {
  pageSize: defaultPageSize,
}

const settings = (state = defaultSettings, action: AnyAction) => {
  switch (action.type) {
    case 'SET_SETTINGS':
      const newState = {
        pageSize: action.pageSize,
      }

      return {
        ...state,
        ...newState,
      }

    default:
      return state
  }
}

export default settings

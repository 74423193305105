import { appName, appPublicUrl, brandLogo } from 'consts'
import { NavLink } from 'react-router-dom'

const getBrandContent = () => {
  return (
    <div className='logo'>
      <NavLink to='/'>
        <img src={appPublicUrl + brandLogo} alt='Application Logo' />
      </NavLink>
      <p>{appName.toUpperCase()}</p>
    </div>
  )
}

export default getBrandContent

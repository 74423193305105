import { useState } from 'react'
import useInit from 'components/Widget/hooks/useInit'

interface WidgetProps {
  setShowOverlay: Function
}

const Widget: React.FunctionComponent<WidgetProps> = (props: WidgetProps) => {
  const [widgetUrl, setWidgetUrl] = useState<string | undefined>(undefined)

  useInit({
    setWidgetUrl,
    setShowOverlay: props.setShowOverlay,
  })

  return (
    <>
      <iframe
        style={{ overflow: 'hidden', height: '100%', width: '100%', minHeight: '900px' }}
        height='100%'
        width='100%'
        src={widgetUrl}
      />
    </>
  )
}

export default Widget

import * as React from 'react'
import { Nav } from 'reactstrap'
import Page from 'shared/types/Page'
import MenuItem from './MenuItem'

interface SubMenuProps {
  pages: Page[]
}

const SubMenu: React.FunctionComponent<SubMenuProps> = (props) => {
  return (
    <Nav className='sub-nav'>
      {props.pages.map((props: any, key: number | string) => {
        return (
          <MenuItem
            key={props.name + '-sub-' + key}
            label={props.name}
            link={props.path}
            icon={props.icon}
          />
        )
      })}
    </Nav>
  )
}

export default SubMenu

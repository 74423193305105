import React, { lazy, useState } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { AuthProvider, RequireAuth } from 'shared/components/App/Auth'
import NotFound from 'shared/components/NotFound'
import Pages from 'components/AppRouter/Pages'
import Footer from 'shared/components/Footer'
import { loginViewModes } from 'consts'
import ContactUs from 'pages/ContactUs'
import ResetPasswordRequest from 'pages/ResetPasswordRequest'
import SetNewPassword from 'pages/SetNewPassword'

const Login = lazy(() => import('shared/containers/Login'))

interface AppRouterProps {}

const AppRouter: React.FunctionComponent<AppRouterProps> = () => {
  const [viewMode, setViewMode] = useState<any>(loginViewModes.login)

  const FooterContent = () => {
    return (
      <>
        <div className='d-flex justify-content-center'>
          <hr className='w-25' />
        </div>
        {viewMode !== loginViewModes.login && (
          <div className='text-center small'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                setViewMode(loginViewModes.login)
              }}
            >
              Login
            </a>
          </div>
        )}
        {![loginViewModes.resetPassword, loginViewModes.setNewPassword].includes(viewMode) && (
          <div className='text-center small'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                setViewMode(loginViewModes.resetPassword)
              }}
            >
              Can't access your account?
            </a>
          </div>
        )}
        {viewMode !== loginViewModes.contactUs && (
          <div className='text-center small'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                setViewMode(loginViewModes.contactUs)
              }}
            >
              Contact Us
            </a>
          </div>
        )}
        <Footer />
      </>
    )
  }

  const PostLoginFormContent = () => {
    return (
      <>
        {viewMode === loginViewModes.contactUs && <ContactUs />}
        {viewMode === loginViewModes.resetPassword && (
          <ResetPasswordRequest
            usernameFieldLabel='Email'
            usernameFieldName='email'
            setViewMode={setViewMode}
          />
        )}
        {viewMode === loginViewModes.setNewPassword && (
          <SetNewPassword
            loginRoute='/login'
            usernameFieldLabel='Email'
            usernameFieldName='email'
            setViewMode={setViewMode}
          />
        )}
      </>
    )
  }

  return (
    <AuthProvider>
      <Routes>
        {Pages.map((props: any, key: number | string) => {
          const PageComponent = props.component

          return (
            <Route
              key={props.name}
              path={props.path}
              element={
                <RequireAuth roles={props.roles}>
                  <PageComponent />
                </RequireAuth>
              }
            />
          )
        })}
        <Route
          path='/login/:token?'
          element={
            <Login
              currentViewMode={viewMode}
              setViewMode={setViewMode}
              loginViewMode={loginViewModes.login}
              footerContent={<FooterContent />}
              postLoginFormContent={<PostLoginFormContent />}
              usernameFieldLabel='Email'
              usernameFieldName='email'
              onLoaded={(props, params, navigate) => {
                if (params.hasOwnProperty('token')) {
                  props.setViewMode(loginViewModes.setNewPassword)
                }
              }}
            />
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </AuthProvider>
  )
}

export default AppRouter

import { axiosGet } from 'shared/api/axios'
import { apiUrls } from 'consts'
import { getCustomData } from 'shared/components/App/Auth'

interface exportPaymentsWithoutStatementsProps {
  setShowOverlay: Function
}

const exportPaymentsWithoutStatements = async (props: exportPaymentsWithoutStatementsProps) => {
  let response: any = false

  props.setShowOverlay(true)
  response = await axiosGet(
    apiUrls.administrators + '/' + apiUrls.actionExportPaymentsWithoutStatements,
    {},
    props.setShowOverlay,
    {
      responseType: 'blob',
    },
  ).then(async (response) => {
    props.setShowOverlay(false)

    if (response === undefined) {
      return
    }
    const retText: any = await response?.data?.text()
    if (response.status === 200) {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href

      const entityCode = getCustomData('entity')?.code ?? '-'

      link.setAttribute('download', 'Export_Payments_without_Statements-' + entityCode + '.csv')
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
    return response
  })

  return response
}

export default exportPaymentsWithoutStatements

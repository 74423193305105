import { useEffect } from 'react'

interface useInitCentersProps {
  getRows: Function
  setRows: Function
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  currentEntity?: {} | null
}

const useInit = (props: useInitCentersProps) => {
  useEffect(() => {
    props
      .getRows({
        setDeleteEntryId: props.setDeleteEntryId,
        setShowModal: props.setShowModal,
      })
      .then((data: any) => {
        props.setRows(data)
        props.setShowOverlay(false)
      })
  }, [props.currentEntity])
}

export default useInit

import MessageProps from 'types/MessageProps'
import PaymentStatusProps from 'types/PaymentStatusProps'

const appName = 'Payment System'

const brandLogo = 'images/header-logo.png'

const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL
    : (window as any).REACT_APP_API_URL

const appPublicUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_PUBLIC_URL
    : (window as any).REACT_APP_PUBLIC_URL

const apiUrls = {
  actionEntityImage: 'image',
  actionWidget: 'widget',
  actionAccount: 'account',
  actionSynchronize: 'synchronize',
  actionRecent: 'recent',
  actionDownload: 'download',
  actionAll: 'all',
  entities: 'entities',
  users: 'users',
  administrators: 'administrators',
  paymentDetails: 'payment-details',
  payments: 'payments',
  actionSort: 'sort',
  actionSave: 'save',
  actionChangeEntity: 'change-entity',
  actionImport: 'import',
  actionExport: 'export',
  actionExportPaymentsWithoutStatements: 'export-payments-without-statements',
  actionExportStatementsWithoutFiles: 'export-statements-without-files',
  actionInstructions: 'instructions',
  actionChangePassword: 'change-password',
  actionUpdateAddress: 'update-address',
  actionGetSelectedEntity: 'get-selected-entity',
  actionResetPasswordRequest: 'reset-password-request',
  actionResetPassword: 'reset-password',
  actionGetFilter: 'get',
  actionSaveFilter: 'save',
}

const loginViewModes = {
  login: 1,
  resetPassword: 2,
  contactUs: 3,
  setNewPassword: 4,
}

const pageSizes = [10, 15, 20, 25, 35, 50]

const defaultPageSize = 10

const defaultAxiosContentType = 'application/json' //application/form-data; charset=UTF-8

const tableColumnWidths = {
  sortColumn: '3rem',
  id: '4rem',
  action: '5rem',
  extended_action: '19rem',
}

const emptyMessage: MessageProps = {
  text: '',
  status: '',
  display: '',
}

const roles = {
  admin: 'admin',
  user: 'user',
}

const statuses = {
  pending: 'pending',
  active: 'active',
}

//https://docs.trolley.com/api/#list-of-statuses
const paymentStatuses: Array<PaymentStatusProps> = [
  { name: 'failed' },
  { name: 'pending' },
  { name: 'processed' },
  { name: 'processing' },
  { name: 'returned' },
]

export {
  apiBaseUrl,
  apiUrls,
  appName,
  appPublicUrl,
  brandLogo,
  defaultAxiosContentType,
  defaultPageSize,
  emptyMessage,
  pageSizes,
  roles,
  statuses,
  tableColumnWidths,
  loginViewModes,
  paymentStatuses,
}

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row, Tooltip, OverlayTrigger, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resChangePassword from './resolvers/resChangePassword'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from './utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import * as Icon from 'react-bootstrap-icons'
import { togglePasswordInput, generateRandomPassword } from 'utils/passwordUtils'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { getCustomData } from 'shared/components/App/Auth'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { apiUrls, roles } from 'consts'

interface UserChangePasswordProps {
  isAdminPasswordChange?: boolean
}

const UserChangePassword: React.FunctionComponent<UserChangePasswordProps> = (
  props: UserChangePasswordProps,
) => {
  const [isAdminPasswordChange] = useState<boolean>(props.isAdminPasswordChange ?? false)
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const [passwordInputType, setPasswordInputType] = useState<string>('password')

  const params = useParams()

  let id: any = params?.id ?? getCustomData('userId')

  const navigate = useNavigate()

  const submitRef = React.createRef<HTMLButtonElement>()

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resChangePassword,
  })

  useInit({
    getValues,
    id,
    reset,
    setDataLoaded,
    setShowOverlay,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      actionInsert={
        <Button
          disabled={showOverlay}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            setError: setError,
            id,
            apiEndpoint: props.isAdminPasswordChange ? apiUrls.administrators : apiUrls.users,
            name: isAdminPasswordChange ? 'New Administrator Password' : 'New User Password',
            successNav: isAdminPasswordChange
              ? '/administrators'
              : params?.id !== undefined
              ? '/users'
              : '/account',
          }),
        )}
        id='main-form'
      >
        <InputWrapper label='Name' name='name' errors={errors}>
          <Form.Control disabled={true} type='text' {...register('concatened')} />
        </InputWrapper>

        <InputWrapper label='Email' name='email' errors={errors}>
          <Form.Control disabled={true} type='text' {...register('email')} />
        </InputWrapper>

        <InputWrapper label='New Password' name='password_api' errors={errors}>
          <InputGroup className='mb-3'>
            <Form.Control type={passwordInputType} {...register('password_api')} />
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Generate Password</Tooltip>}
            >
              <Button
                variant='primary'
                onClick={() => {
                  const randomPassword: string = generateRandomPassword()
                  setValue('password_api', randomPassword)
                  setValue('passwordConfirmation', randomPassword)
                }}
              >
                <Icon.Key title='Generate random password' />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  {passwordInputType === 'password' ? 'Show password' : 'Hide password'}
                </Tooltip>
              }
            >
              <Button
                variant='warning'
                onClick={() => setPasswordInputType(togglePasswordInput(passwordInputType))}
              >
                {passwordInputType === 'password' ? <Icon.Unlock /> : <Icon.Lock />}
              </Button>
            </OverlayTrigger>
          </InputGroup>
        </InputWrapper>

        <InputWrapper label='Password Confirmation' name='passwordConfirmation' errors={errors}>
          <InputGroup className='mb-3'>
            <Form.Control type={'password'} {...register('passwordConfirmation')} />
          </InputGroup>
        </InputWrapper>

        <Row>
          <div className='update ml-auto mr-auto'>
            <Button disabled={!dataLoaded} ref={submitRef} type='submit' className='d-none'>
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </Layout>
  )
}

export default UserChangePassword

import { useEffect } from 'react'
import getModalBody from '../utils/getModalBody'

interface useInitProps {
    url: string
    showModal: boolean
    setShowModal: Function
    deleteEntryId: Number | Boolean
    entityName: string
    getRows: Function
    setRows: Function
    setDeleteEntryId: Function
    setModalBody: Function
    setModalLoaded: Function
    deleteCheck?: Function
}

const useInit = (props: useInitProps) => {

    useEffect(() => {
        if (props.deleteEntryId !== false) {
            props.setModalLoaded(false)
            getModalBody(props).then(bodyElement => {
                props.setModalBody(bodyElement)
                props.setModalLoaded(true)
            })
        }
    }, [props.deleteEntryId])
}

export default useInit
import { useEffect } from 'react'

interface useInitPaymentDetailsProps {
  getRows: Function
  setRows: Function
  synchronizeEntity: Function
  setDeleteEntryId: Function
  setShowModal: Function
  setShowOverlay: Function
  currentEntity: {} | null
  syncCount: number
  setSyncCount: Function
}

const useInit = (props: useInitPaymentDetailsProps) => {
  useEffect(() => {
    props
      .getRows({
        setDeleteEntryId: props.setDeleteEntryId,
        setShowModal: props.setShowModal,
        setShowOverlay: props.setShowOverlay,
        synchronizeEntity: props.synchronizeEntity,
        setSyncCount: props.setSyncCount,
      })
      .then((data: any) => {
        props.setRows(data)
        props.setShowOverlay(false)
      })
  }, [props.currentEntity, props.syncCount])
}

export default useInit

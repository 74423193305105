import { axiosDelete } from 'shared/api/axios'
import { AxiosError } from 'axios'
import Store from 'redux/Store'
import setMessage from 'shared/redux/actions/setMessage'
import displayError from './displayError'
import displayGenError from './displayGenError'

interface deleteEntryProps {
  url: string
  id: Number | Boolean
  entityName: string
  setShowModal: Function
  getRows: Function
  setRows: Function
  setDeleteEntryId: Function
}

const deleteEntry = async (props: deleteEntryProps) => {
  const response = await axiosDelete(props.url + '/' + props.id, {}, (err: AxiosError) => {
    const errMsg: string = err.response!.data?.message ?? 'An error occurred'
    displayError({ errorMessage: errMsg, setShowOverlay: () => {} })
    return err.response
  }).then(() => {
    Store.dispatch(setMessage(props.entityName + ' has been deleted.', 'success', 'instant'))
  })
  props.setShowModal(false)
  props
    .getRows({
      setShowModal: props.setShowModal,
      setDeleteEntryId: props.setDeleteEntryId,
    })
    .then((data: any) => {
      props.setRows(data)
    })
}

export default deleteEntry

import { axiosGet } from 'shared/api/axios'

const getAll = async (endpoint: string, showInactive: boolean = false) => {
  const requestParams = showInactive ? { all: 1 } : {}

  const response = await axiosGet(endpoint, requestParams)

  return response.data
}

export default getAll

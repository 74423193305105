import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resAddEntity from './resolvers/resAddEntity'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import getBrandContent from 'utils/getBrandContent'
import BackButton from 'shared/components/BackButton'
import Footer from 'shared/components/Footer'
import getDomain from 'utils/getDomain'
import { Col } from 'reactstrap'

const AddEntity: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [logo, setLogo] = useState<string | null | undefined>(null)

  const submitRef = React.createRef<HTMLButtonElement>()

  const { id } = useParams()

  const navigate = useNavigate()

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resAddEntity,
  })

  useInit({
    getValues,
    id,
    reset,
    setDataLoaded,
    setShowOverlay,
    setLogo,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      headerInsert={<BackButton url='/entities' text='Entities' />}
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            id,
            name: 'Entity',
            successNav: '/entities',
            setError,
          }),
        )}
        id='main-form'
      >
        <InputWrapper label='Name' name='name' errors={errors}>
          <Form.Control type='text' {...register('name')} />
        </InputWrapper>

        <InputWrapper label='Subdomain' name='subdomain' errors={errors}>
          <InputGroup className='mb-3'>
            <Form.Control type='text' {...register('subdomain')} />
            <InputGroup.Text>.{getDomain(window.location.href)}</InputGroup.Text>
          </InputGroup>
        </InputWrapper>

        <InputWrapper label='Code' name='code' errors={errors}>
          <Form.Control type='text' {...register('code')} />
        </InputWrapper>

        <InputWrapper label='Access Key' name='api_key' errors={errors}>
          <Form.Control type='text' {...register('api_key')} />

          <Form.Text muted>
            {id !== undefined
              ? "Leave this field empty if you don't want to update its value."
              : ''}
          </Form.Text>
        </InputWrapper>
        <InputWrapper label='Secret Key' name='api_secret' errors={errors}>
          <Form.Control type='text' {...register('api_secret')} />
          <Form.Text muted>
            {id !== undefined
              ? "Leave this field empty if you don't want to update its value."
              : ''}
          </Form.Text>
        </InputWrapper>

        {typeof logo === 'string' && <img src={logo} />}

        <InputWrapper label='Image' name='image_file' errors={errors}>
          <Form.Control type='file' {...register('image_file')} />
        </InputWrapper>

        <InputWrapper label='CSS' name='css' errors={errors}>
          <Form.Control as='textarea' rows={5} type='text' {...register('css')} />
        </InputWrapper>

        <fieldset>
          <legend>Widget Colors</legend>
          <Form.Text muted>
            Use either hexadecimal color codes (e.g. #FF00FF) or valid CSS color names (e.g. white).
          </Form.Text>
          <Row className='mt-3'>
            <Col md={6}>
              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Heading'
                name='widget_color_heading'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_heading')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Input Text'
                name='widget_color_input_text'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_input_text')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Input Border'
                name='widget_color_input_border'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_input_border')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Text'
                name='widget_color_text'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_text')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Subtext'
                name='widget_color_sub_text'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_sub_text')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Background'
                name='widget_color_background'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_background')} />
              </InputWrapper>
            </Col>
            <Col md={6}>
              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Border'
                name='widget_color_border'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_border')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Primary Color'
                name='widget_color_success'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_primary')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Success Color'
                name='widget_color_success'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_success')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Error Color'
                name='widget_color_error'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_error')} />
              </InputWrapper>

              <InputWrapper
                colAttributes={{ md: 12 }}
                label='Widget Warning Color'
                name='widget_color_warning'
                errors={errors}
              >
                <Form.Control type='text' {...register('widget_color_warning')} />
              </InputWrapper>
            </Col>
          </Row>
        </fieldset>
        <Button type='submit' name='save' ref={submitRef} className='d-none'></Button>
      </Form>
    </Layout>
  )
}

export default AddEntity

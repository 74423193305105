import { AnyAction } from 'redux';

interface MessageState {
    text: string;
    status: string;
    display: string;
}

const noMessage: MessageState = {
    text: '',
    status: '',
    display: 'onNavigate'
};

const message = (state = noMessage, action: AnyAction) => {
    switch (action.type) {
        case 'SET_MESSAGE':
          
            const newState = {
                text: action.text,
                status: action.status,
                display: action.display
            }

            return {
                ...state,
                ...newState
            }
            
        default:
            return state
    }
}
  
  export default message;
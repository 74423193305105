import { apiUrls } from 'consts'
import { useEffect } from 'react'
import getAll from 'utils/getAll'

interface useInitAccountProps {
  setAccountInfo: Function
  setShowOverlay: Function
}

const useInit = (props: useInitAccountProps) => {
  useEffect(() => {
    const accountInfoRequest = getAll(apiUrls.users + '/' + apiUrls.actionAccount).then((data) => {
      props.setAccountInfo(data)
    })

    Promise.all([accountInfoRequest]).then(() => {
      props.setShowOverlay(false)
    })
  }, [])
}

export default useInit

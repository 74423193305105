import { SearchPanel } from '@devexpress/dx-react-grid-bootstrap4'
import { IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { NavLink } from 'react-router-dom'

interface SearchPanelInputProps {
  insert?: any
  addUrl?: string
  addText?: string
  inputProps: any
}

const SearchPanelInput = (props: SearchPanelInputProps) => {
  const inputClass = props.insert || ''

  return (
    <div className='d-flex grid-search-panel'>
      <SearchPanel.Input {...props.inputProps} className={inputClass} />
      {props.insert}
      {props.addUrl && (
        <NavLink to={props.addUrl} className='btn btn-primary'>
          <IonIcon
            icon={add}
            className='ionicon'
            style={{ fontSize: '16px', marginBottom: '-3px' }}
          />
          {props.addText}
        </NavLink>
      )}
    </div>
  )
}

export default SearchPanelInput

import { useEffect } from 'react'

interface useInitProps {
  getValues: Function
  reset: Function
  setDataLoaded: Function
  setShowOverlay: Function
  currentEntity: {} | null
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    props.setDataLoaded(true)
    props.setShowOverlay(false)
  }, [props.currentEntity])
}

export default useInit

import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import { axiosPost } from 'shared/api/axios'
import displayGenError from 'shared/utils/displayGenError'

interface downloadStatementProps {
  paymentIdsSelected?: any
  statementsSelected?: any
  setIsDownloadSelectedLoading: Function
  setShowOverlay: Function
}

const downloadStatement = async (props: downloadStatementProps) => {
  let response: any = false

  props.setIsDownloadSelectedLoading(true)

  let data: Array<number> = props.statementsSelected !== undefined ? [] : props.paymentIdsSelected

  if (props.statementsSelected !== undefined) {
    if (typeof props.statementsSelected === 'number') {
      //single attachment download
      data.push(props.statementsSelected)
    } else {
      //multiple payments selected
      if (typeof props.statementsSelected === 'object') {
        for (const [_, value] of Object.entries(props.statementsSelected) as any) {
          data.push(...value)
        }
      }
    }
  }

  const keyname: string = props.statementsSelected !== undefined ? 'statements' : 'payments'

  response = await axiosPost(
    apiUrls.paymentDetails + '/' + apiUrls.actionDownload,
    { [keyname]: data },
    async (err: AxiosError) => {
      let errData = err?.response?.data ?? []

      if (errData instanceof Blob) {
        const errDataText: any = await errData.text()
        errData = JSON.parse(errDataText)
      }

      const message = errData.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
      props.setIsDownloadSelectedLoading(false)
    },
    false,
    {
      responseType: 'blob',
    },
  ).then((response) => {
    if (response?.headers !== undefined) {
      const filename = response.headers['x-filename']

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
    props.setIsDownloadSelectedLoading(false)
  })

  return response
}

export default downloadStatement

import { axiosPut } from 'shared/api/axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'

interface submitFormProps {
  entity_id: number
  setShowOverlay: Function
}

const processEntityChange = async (props: submitFormProps) => {
  const payload = {
    entity_id: props.entity_id,
  }
  return await axiosPut(apiUrls.entities + '/' + apiUrls.actionChangeEntity, payload, () => {})
}

export default processEntityChange

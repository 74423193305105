import Home from 'pages/Home'
import Payments from 'pages/Payments'
import Page from 'shared/types/Page'
import Users from 'pages/Users'
import Entities from 'pages/Entities'
import Administrators from 'pages/Administrators'
import AddEntity from 'pages/AddEntity'
import AddAdministrator from 'pages/AddAdministrator'
import ImportPaymentDetails from 'pages/ImportPaymentDetails'
import Account from 'pages/Account'
import PayoutMethods from 'pages/PayoutMethods'
import TaxInfo from 'pages/TaxInfo'
import UserPasswordChange from 'pages/UserChangePassword'
import { roles } from 'consts'
import PaymentDetails from 'pages/PaymentDetails'
import Statements from 'pages/Statements'
import { iconStyle } from 'shared/consts'
import { IonIcon } from '@ionic/react'
import {
  personCircleOutline,
  tvOutline,
  cardOutline,
  businessOutline,
  fileTrayFullOutline,
  peopleOutline,
  logoPaypal,
  wallet,
  walletOutline,
} from 'ionicons/icons'
import UserAddressChange from 'pages/UserChangeAddress'
import AddPaymentDetails from 'pages/AddPaymentDetails'
import { getCustomData } from 'shared/components/App/Auth'

const AdminPasswordChange = () => {
  return <UserPasswordChange isAdminPasswordChange={true} />
}

const AddPaymentDetailsList = () => {
  return <AddPaymentDetails fromList={true} />
}

const fullName = 'My Account'
// getCustomData('accountInfo')?.name

const Pages: Page[] = [
  {
    name: 'Home',
    component: Home,
    path: '/',
    showInMenu: 'top',
    roles: [roles.admin, roles.user],
    icon: (
      <IonIcon
        icon={tvOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={tvOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  // {
  //   name: 'Home',
  //   pageName: 'Recent Payments',
  //   component: Home,
  //   path: '/',
  //   showInMenu: 'top',
  //   roles: [roles.user],
  //   icon: (
  //     <IonIcon
  //       icon={tvOutline}
  //       className='ionicon'
  //       style={{ color: iconStyle.color, fontSize: iconStyle.size }}
  //     />
  //   ),
  //   iconActive: (
  //     <IonIcon
  //       icon={tvOutline}
  //       className='ionicon'
  //       style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
  //     />
  //   ),
  // },
  {
    name: 'Payments',
    component: Payments,
    path: '/payments',
    showInMenu: 'top',
    icon: (
      <IonIcon
        icon={cardOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={cardOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Add Statement',
    component: AddPaymentDetails,
    path: '/add-statement',
    parentPath: '/statements',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Edit Statement',
    component: AddPaymentDetails,
    path: '/edit-statement/:id',
    parentPath: '/statements',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Edit Statement',
    component: AddPaymentDetailsList,
    path: '/edit-statement-list/:id',
    parentPath: '/statements-list',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Statements',
    component: Statements,
    path: '/statements',
    showInMenu: 'top',
    addLink: '/add-import',
    icon: (
      <IonIcon
        icon={fileTrayFullOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={fileTrayFullOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Statements',
    component: Statements,
    path: '/statements/:id',
    showInMenu: false,
    addLink: '/add-import',
  },
  {
    name: 'Statements - List',
    component: PaymentDetails,
    path: '/statements-list',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Import Statements',
    component: ImportPaymentDetails,
    parentPath: '/statements',
    path: '/import-statements',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Edit Entity',
    component: AddEntity,
    path: '/edit-entity/:id',
    parentPath: '/entities',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Add Entity',
    component: AddEntity,
    path: '/add-entity',
    parentPath: '/entities',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Entities',
    component: Entities,
    path: '/entities',
    showInMenu: 'top',
    roles: [roles.admin],
    icon: (
      <IonIcon
        icon={businessOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={businessOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Payout Methods',
    component: PayoutMethods,
    path: '/payout-methods',
    showInMenu: 'top',
    roles: [roles.user],
    icon: (
      <IonIcon
        icon={logoPaypal}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={logoPaypal}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Tax Info',
    component: TaxInfo,
    path: '/tax-info',
    showInMenu: 'top',
    roles: [roles.user],
    icon: (
      <IonIcon
        icon={wallet}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={walletOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Users',
    component: Users,
    path: '/users',
    showInMenu: 'top',
    roles: [roles.admin],
  },
  {
    name: 'Change Password',
    component: UserPasswordChange,
    path: '/change-password/:id',
    parentPath: '/users',
    showInMenu: false,
  },
  {
    name: 'Change Administrator Password',
    component: AdminPasswordChange,
    path: '/change-administrator-password/:id',
    parentPath: '/administrators',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Change Password',
    component: UserPasswordChange,
    path: '/change-password',
    parentPath: '/account',
    showInMenu: true,
  },
  {
    name: 'Change Address',
    component: UserAddressChange,
    path: '/change-address/:id',
    parentPath: '/users',
    showInMenu: false,
  },
  {
    name: 'Administrators',
    component: Administrators,
    path: '/administrators',
    showInMenu: 'top',
    roles: [roles.admin],
    icon: (
      <IonIcon
        icon={peopleOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={peopleOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Profile',
    component: Account,
    path: '/profile',
    parentPath: '/account',
    showInMenu: true,
  },
  {
    name: 'Change Address',
    component: UserAddressChange,
    path: '/change-address',
    parentPath: '/account',
    showInMenu: true,
    roles: [roles.user],
  },
  {
    name: fullName,
    component: Account,
    path: '/account',
    showInMenu: 'top',
    isDropdown: true,
    showLogout: true,
    icon: (
      <IonIcon
        icon={personCircleOutline}
        className='ionicon'
        style={{ color: iconStyle.color, fontSize: iconStyle.size }}
      />
    ),
    iconActive: (
      <IonIcon
        icon={personCircleOutline}
        className='ionicon'
        style={{ color: iconStyle.colorActive, fontSize: iconStyle.size }}
      />
    ),
  },
  {
    name: 'Edit Administrator',
    component: AddAdministrator,
    path: '/edit-administrator/:id',
    parentPath: '/administrators',
    showInMenu: false,
    roles: [roles.admin],
  },
  {
    name: 'Add Administrator',
    component: AddAdministrator,
    path: '/add-administrator',
    parentPath: '/administrators',
    showInMenu: false,
    roles: [roles.admin],
  },
]

export default Pages

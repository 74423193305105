import React, { Suspense } from 'react'
import AppRouter from 'components/AppRouter'
import { BrowserRouter } from 'react-router-dom'
import { AxiosInterceptor } from 'shared/api/axiosBase'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'open-iconic/font/css/open-iconic-bootstrap.css'
import 'assets/scss/styles.scss'

const App: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <div className='App'>
        <BrowserRouter>
          <AxiosInterceptor>
            <AppRouter />
          </AxiosInterceptor>
        </BrowserRouter>
      </div>
    </Suspense>
  )
}

export default App

import { apiUrls } from 'consts'
import { useEffect } from 'react'
import loadRecord from './../utils/loadRecord'

interface useInitProps {
  getValues: Function
  id: string | undefined
  reset: Function
  setDataLoaded: Function
  setShowOverlay: Function
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    // fetch the device if edit
    if (props.id !== undefined) {
      loadRecord(apiUrls.administrators, props.id).then((data) => {
        props.reset(data)

        props.setDataLoaded(true)
        props.setShowOverlay(false)
      })
    } else {
      props.setDataLoaded(true)
      props.setShowOverlay(false)
    }
  }, [])
}

export default useInit

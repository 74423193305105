import { axiosPost, axiosPut } from 'shared/api/axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import { AxiosError } from 'axios'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  delete props.data['name']

  const formData = {
    ...props.data,
  }

  const catchFunc = (err: AxiosError) => {
    if (err.response!.status === 422) {
      setFormValidationError(err, props.setError, undefined, 'trolley_recipient_id')
    } else {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
    }
  }

  if (props.id === undefined) {
    response = await axiosPost(apiUrls.paymentDetails, formData, catchFunc)
  } else {
    response = await axiosPut(apiUrls.paymentDetails + '/' + props.id, formData, catchFunc)
  }

  return response
}

export default submitForm

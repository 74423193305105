import { axiosPut } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
  apiEndpoint: string
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = {
    password_api: props.data.password_api,
  }

  if (props.id !== undefined) {
    response = await axiosPut(
      props.apiEndpoint + '/' + props.id + '/' + apiUrls.actionChangePassword,
      formData,
      (err: AxiosError) => {
        if (err.response!.status === 422) {
          setFormValidationError(err, props.setError)
        } else {
          const message = err?.response?.data?.message ?? 'An error has occurred'
          displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
        }
      },
    )
  }

  return response
}

export default submitForm

import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import Widget from 'components/Widget'

const PayoutMethods: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)

  return (
    <Layout footerContent={<Footer />} brandContent={getBrandContent()} showOverlay={showOverlay}>
      <>
        <Widget setShowOverlay={setShowOverlay} />
      </>
    </Layout>
  )
}

export default PayoutMethods

import { useEffect } from 'react';
import getPages from 'utils/getPages'

interface useInitProps {
    setPages: Function
    position?: String | boolean | undefined
}

const useInit = (props: useInitProps) => {
    useEffect(() => {
        getPages(props.position).then((pages) => {
            props.setPages(pages)
        })
    }, [])
}

export default useInit
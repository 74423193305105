import Layout from 'shared/layouts/Main'
import React, { useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { NavLink, useNavigate } from 'react-router-dom'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import hasAnyRole from 'shared/utils/hasAnyRole'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import { apiUrls, roles, tableColumnWidths } from 'consts'
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SearchState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import getRows from './utils/getRows'
import useInit from './hooks/useInit'
import DeleteModal from 'shared/components/DeleteModal'
import { IonIcon } from '@ionic/react'
import { add, download } from 'ionicons/icons'
import BackButton from 'shared/components/BackButton'
import exportStatements from 'components/Payments/utils/exportStatements'

const PaymentDetails: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [rows, setRows] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)
  const [currentEntity, setCurrentEntity] = useState<{} | null>({})

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'id', title: '#' },
    { name: 'name', title: 'Recipient' },
    { name: 'trolley_recipient_id', title: 'Recipient #' },
    { name: 'trolley_payment_id', title: 'Payment #' },
    { name: 'company_name', title: 'Company' },
    { name: 'statement_period', title: 'Statement Period' },
    { name: 'mm_code', title: 'MM Code' },
    { name: 'file_name', title: 'File Name' },
    { name: 'action', title: ' ', encodeHtml: false },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'id', width: tableColumnWidths.id },
    { columnName: 'name', width: 'auto' },
    { columnName: 'trolley_recipient_id', width: 'auto' },
    { columnName: 'trolley_payment_id', width: 'auto' },
    { columnName: 'company_name', width: 'auto' },
    { columnName: 'statement_period', width: 'auto' },
    { columnName: 'mm_code', width: 'auto' },
    { columnName: 'file_name', width: 'auto' },
    { columnName: 'action', width: tableColumnWidths.action },
  ]

  useInit({
    getRows,
    setRows,
    setDeleteEntryId,
    setShowModal,
    setShowOverlay,
    currentEntity,
  })

  const SearchPanelInputComponent = (props) => {
    const insert: any = (
      <>
        <BackButton url='/statements' text='Back to Statements' />
        <Button
          variant='success'
          onClick={(e) => {
            e.preventDefault()
            exportStatements({
              setShowOverlay,
            })
          }}
          className='btn btn-primary me-3'
        >
          <IonIcon
            icon={download}
            className='ionicon'
            style={{ fontSize: '16px', marginBottom: '-3px' }}
          />
          Export CSV
        </Button>
      </>
    )

    return SearchPanelInput({
      insert: insert,
      inputProps: props,
    })
  }

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='small min-mobile-width with-action' />
  )

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={
        hasAnyRole([roles.admin]) ? (
          <>
            <HeaderEntitySelect
              setShowOverlay={setShowOverlay}
              handleEntityChanged={(entity) => setCurrentEntity(entity)}
            />
          </>
        ) : undefined
      }
    >
      <>
        <h1 className='trolley-header'>Statements - List</h1>
        <Grid rows={rows} columns={columns}>
          <SortingState defaultSorting={[{ columnName: 'id', direction: 'asc' }]} />
          <SearchState defaultValue='' />
          <IntegratedFiltering />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <Toolbar />
          <SearchPanel inputComponent={SearchPanelInputComponent} />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
        </Grid>

        <DeleteModal
          url={apiUrls.paymentDetails}
          showModal={showModal}
          setShowModal={setShowModal}
          deleteEntryId={deleteEntryId}
          setDeleteEntryId={setDeleteEntryId}
          entityName='Statement'
          getRows={getRows}
          setRows={setRows}
        />
      </>
    </Layout>
  )
}

export default PaymentDetails

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object({
  password_api: yup
    .string()
    .min(8, 'New Password must be at least 8 characters')
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
      'New Password must contain at least one uppercase letter, one lowercase letter, one digit and one special symbol.',
    )
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .test('passwords-match', 'Passwords must match.', function (value) {
      return this.parent.password_api === value
    }),
})

const resChangePassword = yupResolver(schema)

export default resChangePassword

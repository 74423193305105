import { AxiosError } from 'axios'

const setFormValidationError = async (
  error: AxiosError,
  setError: Function,
  mappedAttributes?: Map<string, string>,
  showErrorAtDefaultAttribute?: string,
) => {
  let errData = error?.response?.data ?? []

  if (errData instanceof Blob) {
    const errDataText = await errData.text()
    errData = JSON.parse(errDataText)
  }

  const mapErrors: boolean = showErrorAtDefaultAttribute !== undefined
  if (errData instanceof Array) {
    errData.forEach((element) => {
      const { field, message } = element
      let formField: string | undefined = field
      if (mapErrors) {
        formField = mappedAttributes?.has(field)
          ? mappedAttributes.get(field)
          : showErrorAtDefaultAttribute
      }
      setError(formField, { type: 'custom', message: message })
    })
  }
}

export default setFormValidationError

import { axiosPost, axiosPut } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls, roles } from 'consts'
import { getCustomData } from 'shared/components/App/Auth'
import displayGenError from 'shared/utils/displayGenError'
import hasAnyRole from 'shared/utils/hasAnyRole'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  setError: Function
  id: string | undefined
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = {
    ...props.data,
  }

  const catchFn = (err: AxiosError) => {
    if (err.response!.status === 422) {
      let mappedAttributes: Map<string, string> = new Map()
      mappedAttributes.set('firstname', 'firstname')
      mappedAttributes.set('lastname', 'lastname')
      mappedAttributes.set('email', 'email')
      mappedAttributes.set('password_hash', 'password_api')
      mappedAttributes.set('password_api', 'password_api')
      setFormValidationError(err, props.setError, mappedAttributes, 'email')
    } else {
      displayGenError({ setShowOverlay: props.setShowOverlay })
    }
  }

  if (props.id === undefined) {
    response = await axiosPost(apiUrls.administrators, formData, catchFn)
  } else {
    response = await axiosPut(apiUrls.administrators + '/' + props.id, formData, catchFn)
  }

  return response
}

export default submitForm

import { axiosPost } from 'shared/api/axios'
import { getCustomData } from 'shared/components/App/Auth'

const logout = async (): Promise<any> => {
  const data = { 'refresh-token': getCustomData('refreshToken') }
  const response = await axiosPost('logout', data).then((data: any) => {
    return data
  })

  if (response !== undefined) {
    return response.data
  }

  return null
}

export default logout

import * as React from 'react';

interface NotFoundProps {

}

const NotFound: React.FunctionComponent<NotFoundProps> = () => {
    
    return (
        <h1>
            404 Not Found
        </h1>
    );
}

export default NotFound;
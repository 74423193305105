import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resAddPaymentDetails from './resolvers/resAddPaymentDetails'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import getBrandContent from 'utils/getBrandContent'
import BackButton from 'shared/components/BackButton'
import Footer from 'shared/components/Footer'

interface AddPaymentDetailsProps {
  fromList?: boolean
}

const AddPaymentDetails: React.FunctionComponent<AddPaymentDetailsProps> = (
  props: AddPaymentDetailsProps,
) => {
  const [fromList] = useState<boolean>(props.fromList ?? false)
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const submitRef = React.createRef<HTMLButtonElement>()

  const { id } = useParams()

  const navigate = useNavigate()

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resAddPaymentDetails,
  })

  useInit({
    getValues,
    id,
    reset,
    setDataLoaded,
    setShowOverlay,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      headerInsert={
        <BackButton
          url={fromList ? '/statements-list' : '/statements'}
          text={fromList ? 'Statements - List' : 'Statements'}
        />
      }
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            id,
            name: 'Statement',
            successNav: fromList ? '/statements-list' : '/statements',
            setError,
          }),
        )}
        id='main-form'
      >
        {id !== undefined && (
          <InputWrapper label='Name' name='name' errors={errors}>
            <Form.Control type='text' {...register('name')} disabled={true} />
          </InputWrapper>
        )}

        <InputWrapper label='Recipient ID' name='trolley_recipient_id' errors={errors}>
          <Form.Control type='text' {...register('trolley_recipient_id')} />
        </InputWrapper>

        <InputWrapper label='Payment ID' name='trolley_payment_id' errors={errors}>
          <Form.Control type='text' {...register('trolley_payment_id')} />
        </InputWrapper>

        <InputWrapper label='Company Name' name='company_name' errors={errors}>
          <Form.Control type='text' {...register('company_name')} />
        </InputWrapper>

        <InputWrapper label='MM Code' name='mm_code' errors={errors}>
          <Form.Control type='text' {...register('mm_code')} />
        </InputWrapper>

        <InputWrapper label='Statement Period' name='statement_period' errors={errors}>
          <Form.Control type='text' {...register('statement_period')} />
        </InputWrapper>

        <InputWrapper label='File Name' name='file_name' errors={errors}>
          <Form.Control type='text' {...register('file_name')} />
        </InputWrapper>

        <Button type='submit' name='save' ref={submitRef} className='d-none'></Button>
      </Form>
    </Layout>
  )
}

export default AddPaymentDetails

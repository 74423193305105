import { AnyAction } from 'redux'

interface EntityNameState {
  name: string
}

const noEntityName: EntityNameState = {
  name: '',
}

const entityName = (state = noEntityName, action: AnyAction) => {
  switch (action.type) {
    case 'SET_ENTITY_NAME':
      const newState = {
        name: action.name,
      }

      return {
        ...state,
        ...newState,
      }

    default:
      return state
  }
}

export default entityName

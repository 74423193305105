import { connect } from 'react-redux'
import HeaderEntitySelect from 'components/HeaderEntitySelect'

const mapStateToProps = (state: any) => {
  return {
    entityName: state.entityName,
  }
}

export default connect(mapStateToProps)(HeaderEntitySelect)

import { axiosGet } from 'shared/api/axios'
import { apiUrls } from 'consts'

interface getRowsProps {
  filter?: {} | null
  recent?: boolean
  statements?: boolean
  filteredPayment?: string
}

const getRows = async (props: getRowsProps) => {
  let getParams = {}
  if (props.filter !== undefined && props.filter !== null && Object.keys(props.filter).length > 0) {
    getParams['filter'] = props.filter
  }

  if (props.recent === true) {
    getParams['recent'] = true
  }
  if (props.statements === true) {
    getParams['statements'] = true
  }

  if (props.filteredPayment !== undefined) {
    getParams['filtered_payment'] = props.filteredPayment
  }

  let response = await axiosGet(apiUrls.payments, getParams)

  let data = response.data

  return data
}

export default getRows

import * as React from 'react';

interface AccessDeniedProps {

}

const AccessDenied: React.FunctionComponent<AccessDeniedProps> = () => {
    
    return (
        <h1>
            403 Access Denied
        </h1>
    );
}

export default AccessDenied;
import { useEffect } from 'react';
import Page from 'shared/types/Page'
import getPages from 'utils/getPages'
import getSubMenu from 'shared/utils/getSubMenu'
import getPageByPath from 'shared/utils/getPageByPath'

interface useInitProps {
    setSubMenuPages: Function
    curPage: Page | null
    curLink: string
    setActiveClass: Function
    setIcon: Function
    curIcon?: any
    curIconActive?: any
    isActive?: boolean
}

const useInit = (props: useInitProps) => {
    useEffect(() => {
        getPages().then((pages) => {
            const subPages = getSubMenu({
                pages,
                curPage: props.curPage,
                curLink: props.curLink
            })
            props.setSubMenuPages(subPages)
        })

        let newActiveClass = ''

        // determine if parent
        if (props.curPage?.parentPath !== undefined) {

            const parentPage = getPageByPath(props.curPage.parentPath)
            if (props.curLink === props.curPage.parentPath ||
                (parentPage?.parentPath !== undefined &&
                    parentPage.parentPath === props.curLink)) {
                newActiveClass = " active parent"
                props.setIcon(props.curIcon ? <span className="icon-cont">{props.curIconActive}</span> : '')
            }
            else {
                props.setIcon(props.curIcon ? <span className="icon-cont">{props.curIcon}</span> : '')
            }

        }

        // determine active if not parent
        if (!newActiveClass.length && (props.curPage != null)) {
            if (props.curLink === props.curPage.path) {
                newActiveClass = ""
                props.setIcon(props.curIcon ? <span className="icon-cont">{props.curIconActive}</span> : '')
            }
            else {
                props.setIcon(props.curIcon ? <span className="icon-cont">{props.curIcon}</span> : '')
            }
        }

        props.setActiveClass(newActiveClass)
    }, [])
}

export default useInit
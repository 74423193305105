import { axiosPost } from 'shared/api/axios'
import { apiUrls } from 'consts'
import { toast } from 'react-toastify'

interface submitFormProps {
  usernameFieldName: string
  data: any
  token: string | undefined
  setIsSetNewPasswordRequestLoading: Function
  setErrorMsg: Function
  navigate: Function
  loginRoute: string
  setViewMode: Function
  viewModeLogin: any
}

const submitForm = async (props: submitFormProps) => {
  props.setIsSetNewPasswordRequestLoading(true)
  let response: any = false

  const formData = {
    [props.usernameFieldName]: props.data[props.usernameFieldName] ?? null,
    password: props.data.password,
  }

  try {
    const response = await axiosPost(
      apiUrls.actionResetPassword + '?token=' + props.token,
      formData,
      false,
    )
    if (response?.data?.message && response.data.status === 'success') {
      toast(response?.data?.message, {
        type: 'success',
      })
      props.setViewMode(props.viewModeLogin)
      props.navigate(props.loginRoute)
    } else {
      response?.data &&
        props.setErrorMsg(
          response?.data?.message ?? 'An error occurred while changing the password',
        )
    }
  } catch (err) {
    props.setErrorMsg('Password Reset Request Failed.')
  }
  props.setIsSetNewPasswordRequestLoading(false)
  return response
}

export default submitForm

import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import PaymentsComponent from 'components/Payments'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import Widget from 'components/Widget'

const RecentPayments: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [showOverlayWidget, setShowOverlayWidget] = useState<boolean>(false)

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay || showOverlayWidget}
    >
      <>
        <h1 className='trolley-header'>Recent Payments</h1>
        <PaymentsComponent
          currentEntity={null}
          enableFilters={false}
          recentPayments={true}
          expandablePayments={false}
          setShowOverlay={setShowOverlay}
        />

        <div className='row mt-5'>
          <div className='col-12 text-center'>
            <a className='btn btn-primary' href='/payments '>
              View all payments
            </a>
          </div>
        </div>

        <Widget setShowOverlay={setShowOverlayWidget} />
      </>
    </Layout>
  )
}

export default RecentPayments


import Pages from 'components/AppRouter/Pages'
import Page from 'shared/types/Page'

const getCurrentPage = (curLoc: any) : Page | null => {
    
    const curBasepath = '/' + curLoc.pathname.split('/')[1]
    const curPage = Pages.find(item => {
        return '/' + item.path.split('/')[1] === curBasepath
    })

    return curPage ?? null
}

export default getCurrentPage
import { axiosPost } from 'shared/api/axios'
import { apiUrls } from 'consts'

interface loginProps {
  event: React.FormEvent<HTMLFormElement>
  usernameFieldName: string
  username: string
  toast: Function
  switchViewMode: Function
  viewModeLogin: number
  setIsResetRequestLoading: Function
  setErrorMsg: Function
}

const handleSubmitResetPassword = async (props: loginProps) => {
  props.event.preventDefault()
  props.setIsResetRequestLoading(true)

  try {
    const response = await axiosPost(
      apiUrls.actionResetPasswordRequest,
      {
        [props.usernameFieldName]: props.username,
      },
      false,
      'application/x-www-form-urlencoded; charset=UTF-8',
    )
    if (response?.data?.message && response.data.status === 'success') {
      props.toast(response?.data?.message, {
        type: 'success',
      })
      props.switchViewMode(props.viewModeLogin)
    } else {
      response?.data && props.setErrorMsg(response.data.message)
    }
  } catch (err) {
    props.setErrorMsg('Password Reset Request Failed.')
  }

  props.setIsResetRequestLoading(false)
}

export default handleSubmitResetPassword

import { useEffect } from 'react';
import Page from 'shared/types/Page'
import getPages from 'utils/getPages'
import getSubMenu from 'shared/utils/getSubMenu'

interface useInitProps {
    setSubMenuPages: Function
    curPage: Page | null
    curLink: string
}

const useInit = (props: useInitProps) => {
    useEffect(() => {
        getPages().then((pages) => {
            const subPages = getSubMenu({
                pages, 
                curPage: props.curPage, 
                curLink: props.curLink,
                forceShow: true
            })
            props.setSubMenuPages(subPages)
        })
    }, [])
}

export default useInit
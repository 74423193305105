import axios from 'axios'
import { apiBaseUrl } from 'consts'
import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Store from 'redux/Store'
import { setIdentity } from 'redux/actions'
import refreshToken from 'shared/utils/refreshToken'

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
})

let refreshTokenFunc: any = undefined

const AxiosInterceptor: React.FunctionComponent<{ children }> = ({ children }) => {
  const navigate = useNavigate()

  useLayoutEffect(() => {
    const errInterceptor = async (error) => {
      const originalRequest = error.config

      if (error?.response?.status !== 401 || originalRequest._retry) {
        return Promise.reject(error)
      }
      originalRequest._retry = true

      try {
        if (!refreshTokenFunc) {
          refreshTokenFunc = refreshToken()
        }

        const accessTokenResponse = await refreshTokenFunc

        if (accessTokenResponse.token === '') {
          navigate('/login', { replace: true, state: { expired: true } })
        }

        Store.dispatch(setIdentity(accessTokenResponse.username, accessTokenResponse.token))

        originalRequest.headers.Authorization = `Bearer ${accessTokenResponse.token}`

        try {
          return await axiosInstance.request(originalRequest)
        } catch (innerError: any) {
          throw innerError
        }
      } catch (err) {
        throw err
      } finally {
        refreshTokenFunc = undefined
      }
    }

    const interceptor = axiosInstance.interceptors.response.use(undefined, errInterceptor)

    return () => axiosInstance.interceptors.response.eject(interceptor)
  }, [])
  return children
}

export default axiosInstance
export { AxiosInterceptor }

import PaymentStatusProps from 'types/PaymentStatusProps'

interface submitFilterFormProps {
  data: any
  handleFilter: Function
}

const submitFilterForm = async (props: submitFilterFormProps) => {
  const paymentStatuses: Array<PaymentStatusProps> | undefined = props.data?.payment_statuses

  //transform payment statuses to an array of string instead of objects
  if (paymentStatuses !== undefined) {
    delete props.data['payment_statuses']
    props.data.payment_statuses = []
    paymentStatuses.forEach((status) => {
      props.data.payment_statuses.push(status.name)
    })
  }

  props.handleFilter(props.data)
}

export default submitFilterForm

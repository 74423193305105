import hasAnyRole from 'shared/utils/hasAnyRole'
import Layout from 'shared/layouts/Main'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { roles } from 'consts'
import { useState } from 'react'
import HeaderEntitySelect from 'containers/HeaderEntitySelect'
import { Button, Col, Row } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'
import { download } from 'ionicons/icons'
import exportStatementsWithoutFiles from './utils/exportStatementsWithoutFiles'
import exportPaymentsWithoutStatements from './utils/exportPaymentsWithoutStatements'

const HomeAdmin: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const [currentEntity, setCurrentEntity] = useState<{} | null>({})

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      headerInsert={
        hasAnyRole([roles.admin]) ? (
          <HeaderEntitySelect
            setShowOverlay={() => {}}
            handleEntityChanged={(entity) => {
              setCurrentEntity(entity)
            }}
          />
        ) : undefined
      }
    >
      <div style={{ minHeight: '75vh' }}>
        <Row>
          <Col md={12} className='my-1'>
            <Button
              variant='primary'
              onClick={(e) => {
                e.preventDefault()
                exportPaymentsWithoutStatements({
                  setShowOverlay: setShowOverlay,
                })
              }}
            >
              <IonIcon
                icon={download}
                className='ionicon'
                style={{ fontSize: '16px', marginBottom: '-3px' }}
              />
              Export Payments without Statements
            </Button>
          </Col>
          <Col md={12} className='my-1'>
            <Button
              variant='primary'
              onClick={(e) => {
                e.preventDefault()
                exportStatementsWithoutFiles({
                  setShowOverlay: setShowOverlay,
                })
              }}
            >
              <IonIcon
                icon={download}
                className='ionicon'
                style={{ fontSize: '16px', marginBottom: '-3px' }}
              />
              Export Statements without Files
            </Button>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default HomeAdmin

import hasAnyRole from 'shared/utils/hasAnyRole'
import { roles } from 'consts'
import RecentPayments from 'pages/RecentPayments'
import HomeAdmin from 'pages/HomeAdmin'

const Home: React.FunctionComponent = () => {
  return hasAnyRole([roles.admin]) ? <HomeAdmin /> : <RecentPayments />
}



export default Home

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import InputWrapper from 'shared/components/InputWrapper'
import resImportPaymentDetails from './resolvers/resImportPaymentDetails'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import Footer from 'shared/components/Footer'
import BackButton from 'shared/components/BackButton'
import getBrandContent from 'utils/getBrandContent'

const submitRef = React.createRef<HTMLButtonElement>()

const ImportPaymentDetails: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const [currentEntity, setCurrentEntity] = useState<{} | null>({})

  const navigate = useNavigate()

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resImportPaymentDetails,
  })

  useInit({
    getValues,
    reset,
    setDataLoaded,
    setShowOverlay,
    currentEntity,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      headerInsert={<BackButton url='/statements' text='Statements' />}
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Import
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            setError,
            id: undefined,
            name: 'Statements',
            successNav: '/statements',
          }),
        )}
        id='main-form'
      >
        <InputWrapper label='File' name='file' errors={errors}>
          <Form.Control type='file' {...register('file')} />
        </InputWrapper>

        <Button type='submit' name='save' ref={submitRef} className='d-none'></Button>
      </Form>
    </Layout>
  )
}

export default ImportPaymentDetails

import getAll from 'utils/getAll'
import { apiUrls } from 'consts'
import ActionButtons from 'shared/components/ActionButtons'

interface getRowsProps {
  setDeleteEntryId: Function
  setShowModal: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.paymentDetails)

  data.forEach((row: any, index: number, dataArray: any) => {
    dataArray[index].action = (
      <ActionButtons
        buttons={[
          {
            type: 'u',
            url: '/edit-statement-list/' + row.id,
          },
          {
            type: 'd',
            id: dataArray[index].id,
            url: '/delete-statement/' + row.id,
            setDeleteEntryId: props.setDeleteEntryId,
            setShowModal: props.setShowModal,
          },
        ]}
      />
    )
  })

  return data
}

export default getRows

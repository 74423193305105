import Store from 'redux/Store'
import { setMessage } from 'redux/actions'

interface displayErrorProps {
    errorMessage: string
    setShowOverlay?: Function
}

const displayError = (props: displayErrorProps) => {
    
    Store.dispatch(setMessage(props.errorMessage, 'error', 'instant'))
    if(props.setShowOverlay !== undefined){
        props.setShowOverlay(false)
    }
    window.scrollTo(0, 0)

    return true
}

export default displayError
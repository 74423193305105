import { axiosGet } from 'shared/api/axios'

const getOne = async (url: string, id: number | string, extend?: String[]) => {
  const route = url + '/' + id
  const response = await axiosGet(
    route + (extend !== undefined ? '?expand=' + extend.join(',') : ''),
  )

  return response.data
}

export default getOne

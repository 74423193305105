import { axiosGet } from 'shared/api/axios'
import { apiUrls } from 'consts'

interface exportStatementsProps {
  setShowOverlay: Function
}

const exportStatements = async (props: exportStatementsProps) => {
  let response: any = false

  props.setShowOverlay(true)
  response = await axiosGet(
    apiUrls.paymentDetails + '/' + apiUrls.actionExport,
    {},
    props.setShowOverlay,
    {
      responseType: 'blob',
    },
  ).then(async (response) => {
    props.setShowOverlay(false)

    if (response === undefined) {
      return
    }
    const retText: any = await response?.data?.text()
    if (response.status === 200) {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'export-statements.csv')
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
    return response
  })

  return response
}

export default exportStatements

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
  .object({
    name: yup
      .string()
      .required('Name is required')
      .trim()
      .min(1, 'Name  must be at least 1 character'),
    subdomain: yup
      .string()
      .required('Subdomain is required')
      .trim()
      .min(1, 'Subdomain  must be at least 1 character'),
    code: yup
      .string()
      .required('Code is required')
      .trim()
      .min(1, 'Code  must be at least 1 character'),
    api_key: yup.string().trim(),
    api_secret: yup.string().trim(),
    image_file: yup
      .mixed()
      .nullable()
      .test('fileSize', 'The file is too large. Limit is 10 MB', (value) => {
        return !value || value.length === 0 || (value && value[0].size <= 10000 * 1024)
      })

      .test('type', 'Uploaded file must be either JPEG, PNG or SVG', (value) => {
        return (
          !value ||
          value.length === 0 ||
          (value &&
            value.length > 0 &&
            ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'].includes(value[0].type))
        )
      }),
  })
  .required()

const resAddEntity = yupResolver(schema)

export default resAddEntity

import { apiUrls } from 'consts'
import { useEffect } from 'react'
import getAll from 'utils/getAll'

interface useInitRecentPaymentsProps {
  setWidgetUrl: Function
  setShowOverlay: Function
}

const useInit = (props: useInitRecentPaymentsProps) => {
  useEffect(() => {
    const widgetRequest = getAll(apiUrls.users + '/' + apiUrls.actionWidget).then((data) => {
      props.setWidgetUrl(data)
    })

    Promise.all([widgetRequest]).then(() => {
      props.setShowOverlay(false)
    })
  }, [])
}

export default useInit

import { NavLink } from 'react-router-dom'
import confirmDelete from 'shared/utils/confirmDelete'
import * as Icon from 'react-bootstrap-icons'
import getAll from 'utils/getAll'
import { apiUrls, roles } from 'consts'
import hasAnyRole from 'shared/utils/hasAnyRole'
import { getCustomData } from 'shared/components/App/Auth'
import ActionButtons from 'shared/components/ActionButtons'

interface getRowsProps {
  setDeleteEntryId: Function
  setShowModal: Function
}

const getRows = async (props: getRowsProps) => {
  let data = await getAll(apiUrls.users)

  const isAdmin = hasAnyRole([roles.admin])

  data.forEach((row: any, index: number, dataArray: any) => {
    if (isAdmin) {
      dataArray[index].action = (
        <ActionButtons
          buttons={[
            {
              type: 'p',
              id: dataArray[index].id,
              url: '/change-password/' + row.id,
              setDeleteEntryId: props.setDeleteEntryId,
              setShowModal: props.setShowModal,
            },
            {
              type: 'a',
              id: dataArray[index].id,
              url: '/change-address/' + row.id,
              setDeleteEntryId: props.setDeleteEntryId,
              setShowModal: props.setShowModal,
            },
          ]}
        />
      )
    }
  })

  return data
}

export default getRows

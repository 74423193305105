import React, { useEffect, useState } from 'react';
import MenuItem from 'shared/components/MenuItem'
import { Nav } from 'react-bootstrap'
import MenuItemDropdown from 'shared/components/MenuItemDropdown'
import MenuItemLogout from 'shared/components/MenuItemLogout'
import hasAnyRole from 'shared/utils/hasAnyRole'
import useInit from './hooks/useInit'

interface MenuProps {
    position?: String | boolean | undefined
    showLogOut?: boolean
}

const Menu: React.FunctionComponent<MenuProps> = (props) => {

    const [pages, setPages] = useState<any>([])

    useInit({
        setPages,
        position: props.position
    })

    return (
        <Nav className="justify-content-end" key={'menu-' + props.position}>
            {pages.map((itemProps: any, key: number | string) => {
                if ((itemProps.showInMenu === props.position) &&
                    (itemProps.parentPath === undefined) &&
                    (itemProps.roles === undefined || hasAnyRole(itemProps.roles))) {
                    return (
                        <>
                            {!itemProps.isDropdown && <MenuItem key={itemProps.name + '-' + key} label={itemProps.name} link={itemProps.path} icon={itemProps.icon} iconActive={itemProps.iconActive}/>}
                            {itemProps.isDropdown && <MenuItemDropdown key={itemProps.name + '-' + key + '-dd'} label={itemProps.name} link={itemProps.path} pages={pages} showLogOut={itemProps.showLogout} />}
                        </>
                    )
                }
            })}
            {props.showLogOut && <MenuItemLogout />}
        </Nav>
    );
}

export default Menu
import { Filter } from '@devexpress/dx-react-grid'

const customFilterWithStatements = (value: any, filter: Filter, row: any) => {
  if (!filter.value.length) {
    return true
  }
  if (value === null) {
    return false
  }

  const substrings: Array<any> = []

  if (Array.isArray(row.statements)) {
    row.statements.forEach((v) => {
      if (v.company_name !== undefined) {
        substrings.push(v.company_name)
      }
      if (v.statement_period !== undefined) {
        substrings.push(v.statement_period)
      }
      if (v.file_name !== undefined) {
        substrings.push(v.file_name)
      }
    })
  }

  return substrings.some(function (v: any) {
    return v.indexOf(filter.value) >= 0
  })
}

export default customFilterWithStatements

import { AxiosError } from 'axios'
import { loginViewModes } from 'consts'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import loadRecord from './../utils/loadRecord'

interface useInitProps {
  token: string
  loginRoute: string
  setPageLoaded: Function
  reset: Function
  setViewMode: Function
  navigate: Function
}

const useInit = (props: useInitProps) => {
  useEffect(() => {
    loadRecord(props.token)
      .then((response) => {
        props.reset(response.data)
        props.setPageLoaded(true)
      })
      .catch((err: AxiosError) => {
        toast(err.response?.data?.message ?? 'An error occurred', {
          type: 'error',
        })
        props.setViewMode(loginViewModes.login)
        props.navigate(props.loginRoute)
      })
  }, [])
}

export default useInit

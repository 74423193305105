const diffInDays = (dateFrom: Date, dateTo: Date): number => {
  return Math.round(Math.abs((dateFrom.valueOf() - dateTo.valueOf()) / 86400000))
}

const formatDate = (date: Date | null): string | null => {
  return date !== null ? date.toLocaleDateString('en-US') : null
}

const formatDateTime = (date: Date | null): string | null => {
  return date !== null ? date.toLocaleString('en-US') : null
}

const stringToDate = (dateStr: string | null): Date | null => {
  return dateStr !== null ? new Date(dateStr) : null
}

export { diffInDays, formatDate, formatDateTime, stringToDate }

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
  .object({
    file: yup
      .mixed()
      .required('File is required')
      .test('type', 'Uploaded file has to be in CSV format', (value) => {
        return value && value.length > 0 && value[0].type === 'text/csv'
      }),
  })
  .required()

const resImportPaymentDetails = yupResolver(schema)

export default resImportPaymentDetails

import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import confirmDelete from 'shared/utils/confirmDelete'
import { IonIcon } from '@ionic/react'
import {
  eyeOutline,
  createOutline,
  trashOutline,
  enterOutline,
  home,
  sync,
  syncOutline,
} from 'ionicons/icons'
import synchronizeEntity from 'pages/Entities/utils/synchronizeEntity'

interface ActionButton {
  type?: string
  content?: React.ReactElement
  url?: string
  function?: Function
  id?: number
  setDeleteEntryId?: Function
  setShowModal?: Function
}

interface ActionButtonsProps {
  buttons: ActionButton[]
}

const ActionButtons: React.FunctionComponent<ActionButtonsProps> = (props: ActionButtonsProps) => {
  const getActionButton = (button: ActionButton, index: number) => {
    let buttonElement: React.ReactElement = <></>

    if (button.type === 'r') {
      const url = typeof button.url !== 'undefined' ? button.url : ''
      buttonElement = (
        <NavLink to={url} key={'button-' + index} title='View'>
          <IonIcon icon={eyeOutline} className='ionicon' style={{ fontSize: '16px' }} />
        </NavLink>
      )
    } else if (button.type === 'u') {
      const url = typeof button.url !== 'undefined' ? button.url : ''

      buttonElement = (
        <NavLink to={url} key={'button-' + index} title='Edit'>
          <IonIcon icon={createOutline} className='ionicon' style={{ fontSize: '16px' }} />
        </NavLink>
      )
    } else if (button.type === 'sync') {
      const url = typeof button.url !== 'undefined' ? button.url : ''
      const fn: Function | undefined =
        typeof button.function !== 'undefined' ? button.function : undefined

      buttonElement = (
        <a
          href={'javascript:void(0);'}
          key={'button-' + index}
          title='Synchronize'
          onClick={(e) => {
            if (typeof fn === 'function') {
              fn()
            }
          }}
        >
          <IonIcon icon={syncOutline} className='ionicon' style={{ fontSize: '16px' }} />
        </a>
      )
    } else if (button.type === 'd') {
      const url = typeof button.url !== 'undefined' ? button.url : ''

      buttonElement = (
        <NavLink
          to={url}
          className='danger'
          key={'button-' + index}
          title='Delete'
          onClick={(e) =>
            confirmDelete({
              e,
              id: typeof button.id === 'number' ? button.id : 0,
              setDeleteEntryId:
                typeof button.setDeleteEntryId !== 'undefined' ? button.setDeleteEntryId : () => {},
              setShowModal:
                typeof button.setShowModal !== 'undefined' ? button.setShowModal : () => {},
            })
          }
        >
          <IonIcon icon={trashOutline} className='ionicon' style={{ fontSize: '16px' }} />
        </NavLink>
      )
    } else if (button.type === 'p') {
      const url = typeof button.url !== 'undefined' ? button.url : ''

      buttonElement = (
        <NavLink to={url} key={'button-' + index} title='Change Password'>
          <IonIcon icon={enterOutline} className='ionicon' style={{ fontSize: '16px' }} />
        </NavLink>
      )
    } else if (button.type === 'a') {
      const url = typeof button.url !== 'undefined' ? button.url : ''

      buttonElement = (
        <NavLink to={url} key={'button-' + index} title='Change Address'>
          <IonIcon icon={home} className='ionicon' style={{ fontSize: '16px' }} />
        </NavLink>
      )
    } else if (typeof button.content !== 'undefined') {
      buttonElement = button.content
    }

    return buttonElement
  }

  return (
    <div className='table-buttons'>
      {props.buttons.map((button: ActionButton, index: number) => {
        return getActionButton(button, index)
      })}
    </div>
  )
}

export default ActionButtons

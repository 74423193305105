import { axiosPost } from 'shared/api/axios'
import { getCustomData } from 'shared/components/App/Auth'

interface RefreshTokenProps {
  username: string
  token: string
}

const refreshToken = async (): Promise<RefreshTokenProps> => {
  const data = { 'refresh-token': getCustomData('refreshToken') }
  const response = await axiosPost('refresh-token', data).then((data: any) => {
    return data
  })

  if (response !== undefined) {
    return response.data
  }

  return {
    username: '',
    token: '',
  }
}

export default refreshToken

import { axiosPost, axiosPut } from 'shared/api/axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import { AxiosError } from 'axios'
import setFormValidationError from 'shared/utils/setFormValidationError'
import { useState } from 'react'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
}

const readFile = async (imageFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const contents = reader.result ? reader.result.toString() : null
      resolve(contents)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(imageFile)
  })
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const imageFiles: any = props.data.image_file ?? null

  if (typeof imageFiles === 'object' && imageFiles.length > 0) {
    const contents = await readFile(imageFiles[0])
    if (typeof contents === 'string') {
      props.data['image'] = contents
    }
  } else {
    delete props.data['image']
  }

  const formData = {
    ...props.data,
  }

  const catchFunc = (err: AxiosError) => {
    if (err.response!.status === 422) {
      let mappedAttributes: Map<string, string> = new Map()
      mappedAttributes.set('name', 'name')
      mappedAttributes.set('code', 'code')
      mappedAttributes.set('subdomain', 'subdomain')
      mappedAttributes.set('api_key', 'api_key')
      mappedAttributes.set('api_secret', 'api_secret')
      mappedAttributes.set('image', 'image')
      mappedAttributes.set('css', 'css')
      setFormValidationError(err, props.setError, mappedAttributes, 'name')
    } else {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
    }
  }

  if (props.id === undefined) {
    response = await axiosPost(apiUrls.entities, formData, catchFunc)
  } else {
    response = await axiosPut(apiUrls.entities + '/' + props.id, formData, catchFunc)
  }

  return response
}

export default submitForm

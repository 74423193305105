import React from 'react'
import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { setIdentity } from 'redux/actions'
import { ReactReduxContext } from 'react-redux'
import Store from 'redux/Store'
import { setCustomData } from 'shared/redux/actions'
import AccessDenied from '../AccessDenied'

// @todo finish this component, see below

interface AuthContextType {
  // username: any;
  // token: any;
  signIn: (
    username: string,
    token: string,
    customData: object | null,
    callback: VoidFunction,
  ) => boolean
  signOut: (callback: VoidFunction) => boolean
}

let AuthContext = React.createContext<AuthContextType>(null!)

function AuthProvider({ children }: { children: React.ReactNode }) {
  // let [username, setUsername] = React.useState<any>(null);
  // let [token, setToken] = React.useState<any>(null);

  let signIn = (
    username: string,
    token: string,
    customData: object | null,
    callback: VoidFunction,
  ) => {
    if (Store.dispatch(setIdentity(username, token))) {
      Store.dispatch(setCustomData(customData))
      // setUsername(username);
      // setToken(token);
      callback()
      return true
    } else {
      return false
    }
  }

  let signOut = (callback: VoidFunction) => {
    Store.dispatch(setIdentity('', false))
    Store.dispatch(setCustomData(null))
    // setUsername('');
    // setToken(false);
    callback()
    return true
  }

  // let value = { username, token, signIn, signOut };
  let value = { signIn, signOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth() {
  return React.useContext(AuthContext)
}

function RequireAuth({ children, roles }: { children: JSX.Element; roles: String[] | undefined }) {
  let auth = useAuth()
  let location = useLocation()
  const { store } = useContext(ReactReduxContext)
  let identity = store.getState().identity
  const userRole = store.getState().customData?.customData?.role
  if (identity.username !== '' && identity.token !== false) {
    // auth.signIn(identity.username, identity.token, () => {});
    // auth.username = identity.username;
    // auth.token = identity.token;
    return roles === undefined || roles.includes(userRole) ? children : <AccessDenied />
  } else {
    return <Navigate to='/login' state={{ from: location }} replace />
  }
}

function getToken() {
  return Store.getState().identity.token
}

function getCustomData(key: string) {
  const customData = Store.getState().customData?.customData
  return customData?.hasOwnProperty(key) ? customData[key] : null
}

function getAllCustomData() {
  return Store.getState().customData?.customData
}

export {
  AuthContext,
  AuthProvider,
  useAuth,
  RequireAuth,
  getToken,
  getCustomData,
  getAllCustomData,
}

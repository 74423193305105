import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import getCurrentPage from 'shared/utils/getCurrentPage'
import useInit from './hooks/useInit'
import SubMenu from 'shared/components/SubMenu'

interface MenuItemProps {
    label: string,
    link: string,
    icon?: any,
    iconActive?: any,
    isActive?: boolean
}

const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    
    let curLoc = useLocation()
    const curPage = getCurrentPage(curLoc)

    const [subMenuPages, setSubMenuPages] = useState<any>([])
    const [activeClass, setActiveClass] = useState<any>('')
    const [icon, setIcon] = useState<any>('')
    
    useInit({
        setSubMenuPages,
        setActiveClass,
        setIcon,
        curPage,
        curLink: props.link,
        curIcon: props.icon,
        curIconActive: props.iconActive,
        isActive: props.isActive
    })

    return (
        <li className="menu-item">
            <NavLink
                to={props.link}
                className={"nav-link" + activeClass}
            >
                {icon}
                <span>{props.label}</span>
            </NavLink>
            {subMenuPages.length > 0 ? <SubMenu pages={subMenuPages} /> : ''}
        </li>
    );
}

export default MenuItem;

import Pages from 'components/AppRouter/Pages'
import Page from 'shared/types/Page'

const getPageByPath = (path: string | undefined) : Page | null => {
    
    const curPage = Pages.find(item => {
        return item.path === path
    })

    return curPage ?? null
}

export default getPageByPath
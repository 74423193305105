import * as React from 'react'
import { iconStyle } from 'shared/consts'
import { IonIcon } from '@ionic/react'
import { logOutOutline } from 'ionicons/icons'
import { NavLink } from "react-router-dom"
import { useAuth } from 'shared/components/App/Auth'
import { useNavigate } from 'react-router-dom'
import logout from 'shared/utils/logout'

const MenuItemLogout: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  return (
    <NavLink
      to='/logout'
      className='nav-link'
      onClick={(e) => {
        e.preventDefault()
        logout()
        auth.signOut(() => navigate('/login'))
      }}
    >
      <span className="icon-cont">
        <IonIcon icon={logOutOutline} className='ionicon' style={{ color: iconStyle.color, fontSize: iconStyle.size }} />
      </span>
      <span>Log Out</span>
    </NavLink>
  )
}

export default MenuItemLogout

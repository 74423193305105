import { useEffect } from 'react'
import { getCustomData } from 'shared/components/App/Auth'
import Store from 'redux/Store'
import { setEntityName } from 'redux/actions'

interface useInitCentersProps {
  getRows: Function
  setRows: Function
  setCurrentEntity: Function
  setDataLoaded: Function
  entityName: string
}

const useInit = (props: useInitCentersProps) => {
  useEffect(() => {
    props.getRows().then((data: any) => {
      props.setRows(data.entities)
      const currentEntity = data.currentEntity ?? getCustomData('entity')
      const entityObj = data.entities.find((row) => row.id === currentEntity?.id)
      props.setCurrentEntity(entityObj)
      Store.dispatch(setEntityName(entityObj?.name))
      props.setDataLoaded(true)
    })
  }, [])
}

export default useInit

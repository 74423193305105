import { FileExcel, FilePdf, FileText, FileWord } from 'react-bootstrap-icons'

const getFileIcon = (extensionName: string, opts: any) => {
  switch (extensionName) {
    case 'pdf':
      return <FilePdf {...opts} className='text-danger' />
    case 'xls':
    case 'xlsx':
      return <FileExcel {...opts} className='text-success' />
    case 'doc':
    case 'docx':
    case 'odt':
      return <FileWord {...opts} className='text-primary' />
    default:
      return <FileText {...opts} className='text-primary' />
  }
}

export default getFileIcon

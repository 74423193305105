import Store from 'redux/Store'
import displayError from './displayError'

interface displayGenErrorProps {
  setShowOverlay: Function
  message?: string
}

const displayGenError = (props: displayGenErrorProps) => {
  return displayError({
    errorMessage: props.message ?? 'An error has occurred.',
    setShowOverlay: props.setShowOverlay,
  })
}

export default displayGenError

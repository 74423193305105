import { axiosPost, axiosPut } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  setError: Function
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = new FormData()
  formData.append('file', props.data.file[0] ?? null)
  const catchFunc = (err: AxiosError) => {
    if (err.response!.status === 422) {
      setFormValidationError(err, props.setError, undefined, 'file')
    } else {
      const message = err?.response?.data?.message ?? 'An error has occurred'
      displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
    }
  }

  response = await axiosPost(
    apiUrls.paymentDetails + '/' + apiUrls.actionImport,
    formData,
    catchFunc,
    'multipart/form-data',
    {
      responseType: 'blob',
    },
  ).then(async (response) => {
    if (response === undefined) {
      return
    }
    const retText: any = await response?.data?.text()
    if (response.status === 200 && retText !== 'true') {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'import-errors.csv') //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }
    return response
  })

  return response
}

export default submitForm

import { useEffect } from 'react'

interface useFocusErrorProps {
    errors: any,
    setFocus: Function
}

const useFocusError = (props: useFocusErrorProps) => {
    useEffect(() => {

        let firstError: boolean | string = false
        if (typeof Object.keys(props.errors)[0] !== 'undefined') {
          firstError = Object.keys(props.errors)[0]
        }
    
        if (firstError) {
            props.setFocus(firstError)
        }
    
      }, [props.errors, props.setFocus])
}

export default useFocusError
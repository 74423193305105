import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
  .object({
    trolley_recipient_id: yup
      .string()
      .required('Recipient ID is required')
      .trim()
      .min(1, 'Recipient ID  must be at least 1 character'),
    trolley_payment_id: yup.string().trim(),
    company_name: yup
      .string()
      .required('Company Name is required')
      .trim()
      .min(1, 'Company Name must be at least 1 character'),
    statement_period: yup
      .string()
      .required('Statement Period is required')
      .trim()
      .min(1, 'Statement Period must be at least 1 character'),
    file_name: yup
      .string()
      .required('File Name is required')
      .trim()
      .min(1, 'File Name must be at least 1 character'),
  })
  .required()

const resAddPaymentDetails = yupResolver(schema)

export default resAddPaymentDetails

const togglePasswordInput = (passwordInputType: string) => {
  return passwordInputType === 'password' ? 'text' : 'password'
}

const shuffle = (string: string): string => {
  let newStr: Array<string> = []
  let str: Array<string> = string.split('')
  while (str.length) {
    newStr.push(str.splice(Math.floor(Math.random() * (str.length - 1)), 1)[0])
  }

  return newStr.join('')
}

const generateRandomPassword = () => {
  const randomString: string =
    Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8)

  const symbols: Array<string> = ['!', '@', '*', '-', '<', '>']
  const digits: Array<string> = ['1', '2', '3', '4', '5', '6', '7', '9', '0']
  const letters: Array<string> = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]

  const concatened: string =
    randomString +
    symbols[Math.floor(Math.random() * symbols.length)] +
    digits[Math.floor(Math.random() * digits.length)] +
    letters[Math.floor(Math.random() * letters.length)].toUpperCase() +
    letters[Math.floor(Math.random() * letters.length)].toLowerCase()

  return shuffle(concatened)
}

export { togglePasswordInput, generateRandomPassword }

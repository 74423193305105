import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import handleSubmitResetPassword from './utils/handleSubmitResetPassword'
import { toast } from 'react-toastify'
import { loginViewModes } from 'consts'

interface ResetPasswordRequestProps {
  usernameFieldLabel?: string
  usernameFieldName?: string
  setViewMode: Function
}

const ResetPasswordRequest: React.FunctionComponent<ResetPasswordRequestProps> = (
  props: ResetPasswordRequestProps,
) => {
  const [name, setName] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [usernameFieldLabel] = useState<string>(props.usernameFieldLabel ?? 'Username')
  const [usernameFieldName] = useState<string>(props.usernameFieldName ?? 'username')
  const [isResetRequestLoading, setIsResetRequestLoading] = useState<boolean>(false)

  return (
    <>
      <div className='color-overlay d-flex justify-content-center align-items-center'>
        <Form
          className='rounded p-4 p-sm-3'
          onSubmit={(event) => {
            handleSubmitResetPassword({
              event,
              usernameFieldName,
              username: name,
              toast,
              switchViewMode: props.setViewMode,
              viewModeLogin: loginViewModes.login,
              setIsResetRequestLoading,
              setErrorMsg,
            })
          }}
        >
          {errorMsg.length ? <p className='error'>{errorMsg}</p> : ''}

          <Form.Group className='mb-3'>
            <Form.Label>{usernameFieldLabel}</Form.Label>
            <Form.Control
              type='text'
              id='username-password-reset'
              placeholder={usernameFieldLabel}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Button disabled={isResetRequestLoading} variant='primary' type='submit'>
            {isResetRequestLoading && (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                &nbsp;
              </>
            )}
            Get Password
          </Button>
        </Form>
      </div>
    </>
  )
}

export default ResetPasswordRequest

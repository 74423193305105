import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import useInit from './hooks/useInit'

interface DeleteModalProps {
  url: string
  showModal: boolean
  setShowModal: Function
  deleteEntryId: Number | Boolean
  entityName: string
  getRows: Function
  setRows: Function
  setDeleteEntryId: Function
}

const DeleteModal: React.FunctionComponent<DeleteModalProps> = (props) => {
  const [modalBody, setModalBody] = useState<React.ReactElement>(<></>)
  const [modalLoaded, setModalLoaded] = useState<boolean>(false)

  useInit({
    ...props,
    setModalBody,
    setModalLoaded,
  })

  return (
    <Modal
      show={props.showModal && modalLoaded}
      onHide={() => props.setShowModal(false)}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {props.entityName}</Modal.Title>
      </Modal.Header>
      {modalBody}
    </Modal>
  )
}

export default DeleteModal

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'shared/layouts/Main'
import { Button, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import InputWrapper from 'shared/components/InputWrapper'
import resChangeAddress from './resolvers/resChangeAddress'
import useInit from './hooks/useInit'
import useFocusError from 'shared/hooks/useFocusError'
import prepareSubmitForm from 'shared/utils/prepareSubmitForm'
import submitForm from './utils/submitForm'
import getBrandContent from 'utils/getBrandContent'
import Footer from 'shared/components/Footer'
import { getCustomData } from 'shared/components/App/Auth'

const UserAddressChange: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  const params = useParams()

  let id: any = params?.id ?? getCustomData('userId')

  const navigate = useNavigate()

  const submitRef = React.createRef<HTMLButtonElement>()

  const {
    control,
    register,
    getValues,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: resChangeAddress,
  })

  useInit({
    getValues,
    id,
    reset,
    setDataLoaded,
    setShowOverlay,
  })

  useFocusError({
    errors,
    setFocus,
  })

  return (
    <Layout
      footerContent={<Footer />}
      brandContent={getBrandContent()}
      showOverlay={showOverlay}
      showCardHeader={true}
      actionInsert={
        <Button
          disabled={!dataLoaded}
          type='submit'
          name='save'
          className='btn-round btn-primary'
          onClick={() => {
            if (submitRef.current != null) {
              submitRef.current.click()
            }
          }}
        >
          Save
        </Button>
      }
    >
      <Form
        onSubmit={handleSubmit((data) =>
          prepareSubmitForm({
            submitForm,
            navigate,
            data,
            setShowOverlay,
            setError: setError,
            id,
            name: 'User Address',
            successNav: params?.id !== undefined ? '/users' : '/account',
          }),
        )}
        id='main-form'
      >
        <h1 className='trolley-header'>Change Address</h1>
        <InputWrapper label='Name' name='name' errors={errors}>
          <Form.Control disabled={true} type='text' {...register('concatened')} />
        </InputWrapper>

        <InputWrapper label='Email' name='email' errors={errors}>
          <Form.Control disabled={true} type='text' {...register('email')} />
        </InputWrapper>

        <InputWrapper label='Street 1' name='street1' errors={errors}>
          <Form.Control type='text' {...register('address.street1')} />
        </InputWrapper>

        <InputWrapper label='Street 2' name='street1' errors={errors}>
          <Form.Control type='text' {...register('address.street2')} />
        </InputWrapper>

        <InputWrapper label='City' name='city' errors={errors}>
          <Form.Control type='text' {...register('address.city')} />
        </InputWrapper>

        <InputWrapper label='Postal Code' name='postal_code' errors={errors}>
          <Form.Control type='text' {...register('address.postal_code')} />
        </InputWrapper>

        <InputWrapper label='Country' name='country' errors={errors}>
          <Form.Control type='text' {...register('address.country')} />
        </InputWrapper>

        <InputWrapper label='State / Province / County' name='region' errors={errors}>
          <Form.Control type='text' {...register('address.region')} />
        </InputWrapper>

        <InputWrapper label='Phone' name='phone' errors={errors}>
          <Form.Control type='text' {...register('address.phone')} />
        </InputWrapper>

        <Row>
          <div className='update ml-auto mr-auto'>
            <Button
              disabled={!dataLoaded}
              ref={submitRef}
              type='submit'
              className='d-none btn-round btn-primary'
            >
              Submit
            </Button>
          </div>
        </Row>
      </Form>
    </Layout>
  )
}

export default UserAddressChange

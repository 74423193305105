import Pages from 'components/AppRouter/Pages'
import Page from 'shared/types/Page'

const getPages = async (position?: String | boolean | undefined) => {
  var pages: Page[]

  if (typeof position !== 'undefined' && position) {
    pages = Pages.filter((page) => {
      return page.showInMenu === position
    })
  } else {
    pages = Pages
  }

  return pages
}

export default getPages

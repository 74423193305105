import { axiosPut } from 'shared/api/axios'
import { AxiosError } from 'axios'
import { apiUrls } from 'consts'
import displayGenError from 'shared/utils/displayGenError'
import setFormValidationError from 'shared/utils/setFormValidationError'

interface submitFormProps {
  data: any
  setShowOverlay: Function
  id: string | undefined
  setError: Function
}

const submitForm = async (props: submitFormProps) => {
  let response: any = false

  const formData = {
    ...props.data?.address,
    id: props.id,
  }

  if (props.id !== undefined) {
    let mappedAttributes: Map<string, string> = new Map()
    mappedAttributes.set('street1', 'street1')
    mappedAttributes.set('street2', 'street2')
    mappedAttributes.set('city', 'city')
    mappedAttributes.set('postal_code', 'postalCode')
    mappedAttributes.set('country', 'country')
    mappedAttributes.set('region', 'region')
    mappedAttributes.set('phone', 'phone')

    response = await axiosPut(
      apiUrls.users + '/' + props.id + '/' + apiUrls.actionUpdateAddress,
      formData,
      (err: AxiosError) => {
        if (err.response!.status === 422) {
          setFormValidationError(err, props.setError, mappedAttributes, 'street1')
        } else {
          const message = err?.response?.data?.message ?? 'An error has occurred'
          displayGenError({ setShowOverlay: props.setShowOverlay, message: message })
        }
      },
    )
  }

  return response
}

export default submitForm

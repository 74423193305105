import { getCustomData } from "shared/components/App/Auth"


const hasAnyRole = (roles : String[]) : boolean => {

    const role : String | null = getCustomData('role')

    if(role === null){
        return false;
    }
    return roles.includes(role)
}

export default hasAnyRole
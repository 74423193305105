import getOne from 'utils/getOne'

const loadRecord = async (url: string, id: number | string) => {
  const retData = await getOne(url, id).then((data) => {
    const loadedData = {
      ...data,
      role: data?.role?.toLowerCase(),
    }

    return loadedData
  })

  return retData
}

export default loadRecord

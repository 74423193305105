import { GroupBase, ClassNamesConfig } from 'react-select'

const selectClassNames: ClassNamesConfig<any, boolean, GroupBase<any>> = {
    container: (state) => 'select-container',
    control: (state) => 'control',
    input: (state) => 'input',
    placeholder: (state) => 'single-value',
    indicatorsContainer: (state) => 'indicator-container',
    indicatorSeparator: (state) => 'indicator-separator',
    singleValue: (state) => 'single-value',
    valueContainer: (state) => 'value-container',
    menu: (state) => 'menu',
    option: (state) => state.isSelected ? 'option active' : 'option'
}

export { selectClassNames }
import { Button, Modal } from 'react-bootstrap'
import deleteEntry from 'shared/utils/deleteEntry'

interface getModalBodyProps {
    url: string
    showModal: boolean
    setShowModal: Function
    deleteEntryId: Number | Boolean
    entityName: string
    getRows: Function
    setRows: Function
    setDeleteEntryId: Function
    deleteCheck?: Function
}

const getModalBody = async (props: getModalBodyProps) => {

    var modalBody: React.ReactElement | string = ''

    const deletionBody = <>
        <Modal.Body>Are you sure you want to delete this {props.entityName}?</Modal.Body>
        <Modal.Footer>
            <Button variant="light" onClick={() => { props.setShowModal(false) }}>
                Go Back
            </Button>
            <Button variant="primary" onClick={(e) => {
                e.currentTarget.disabled = true;
                deleteEntry({
                    url: props.url,
                    id: props.deleteEntryId,
                    entityName: props.entityName,
                    setShowModal: props.setShowModal,
                    getRows: props.getRows,
                    setRows: props.setRows,
                    setDeleteEntryId: props.setDeleteEntryId
                })
            }}>
                Delete
            </Button>
        </Modal.Footer>
    </>

    if (typeof props.deleteCheck !== 'undefined') {
        
        const checkMessages = await props.deleteCheck(props.deleteEntryId)
            
        if (checkMessages.length > 0) {
            modalBody = <Modal.Body>{checkMessages[0]}</Modal.Body>
        }
        else {
            modalBody = deletionBody
        }
    }
    else {
        modalBody = deletionBody
    }

    return modalBody
}

export default getModalBody
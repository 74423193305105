import { useState } from 'react'
import Layout from 'shared/layouts/Main'
import useInit from './hooks/useInit'
import DeleteModal from 'shared/components/DeleteModal'
import { apiUrls, defaultPageSize, pageSizes, tableColumnWidths, roles } from 'consts'
import getRows from './utils/getRows'
import {
  PagingState,
  SearchState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  SortingState,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  SearchPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
  TableSelection,
  PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid/dist/dx-react-grid'
import getBrandContent from 'utils/getBrandContent'
import SearchPanelInput from 'shared/components/SearchPanelInput'
import Footer from 'shared/components/Footer'

const Administrators: React.FunctionComponent = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true)
  const [rows, setRows] = useState<any>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleteEntryId, setDeleteEntryId] = useState<boolean | number>(false)

  const [currentEntity, setCurrentEntity] = useState<{} | null>({})

  useState<boolean>(false)

  const columns: { name: string; title: string; encodeHtml?: boolean; getCellValue?: any }[] = [
    { name: 'id', title: 'ID' },
    { name: 'firstname', title: 'Firstname' },
    { name: 'lastname', title: 'Lastname' },
    { name: 'email', title: 'Email' },
    { name: 'role', title: 'Role' },
    { name: 'action', title: ' ', encodeHtml: false },
  ]

  const columnWidths: TableColumnWidthInfo[] = [
    { columnName: 'id', width: tableColumnWidths.id },
    { columnName: 'firstname', width: 'auto' },
    { columnName: 'lastname', width: 'auto' },
    { columnName: 'email', width: 'auto' },
    { columnName: 'role', width: 'auto' },
    { columnName: 'action', width: tableColumnWidths.extended_action },
  ]

  useInit({
    getRows,
    setRows,
    setDeleteEntryId,
    setShowModal,
    setShowOverlay,
    currentEntity,
  })

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className='min-mobile-width with-action' />
  )

  const SearchPanelInputComponent = (props) => {
    return SearchPanelInput({
      inputProps: props,
      addUrl: '/add-administrator',
      addText: 'Add',
    })
  }

  const PagingContainer = (props) => {
    return <PagingPanel.Container {...props} className='table-footer' />
  }

  return (
    <Layout footerContent={<Footer />} brandContent={getBrandContent()} showOverlay={showOverlay}>
      <>
        <Grid rows={rows} columns={columns}>
          <SortingState defaultSorting={[{ columnName: 'email', direction: 'asc' }]} />
          <SearchState defaultValue='' />
          <IntegratedFiltering />
          <IntegratedSorting />
          <Toolbar />
          <SearchPanel inputComponent={SearchPanelInputComponent} />
          <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize} />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} />
          <TableColumnResizing defaultColumnWidths={columnWidths} resizingMode='nextColumn' />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[]} containerComponent={PagingContainer} />
        </Grid>
        <DeleteModal
          url={apiUrls.administrators}
          showModal={showModal}
          setShowModal={setShowModal}
          deleteEntryId={deleteEntryId}
          setDeleteEntryId={setDeleteEntryId}
          entityName='Administrator'
          getRows={getRows}
          setRows={setRows}
        />
      </>
    </Layout>
  )
}

export default Administrators

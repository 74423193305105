const getDomain = (url: string): string => {
  //find & remove protocol (http, ftp, etc.) and get hostname
  let hostname: string = ''
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }

  //remove subdomain
  hostname = hostname.split('.').slice(1).join('.')

  //find & remove port number
  hostname = hostname.split(':')[0]
  //find & remove "?"
  hostname = hostname.split('?')[0]

  return hostname
}

export default getDomain

import { Row, Col, Form } from 'react-bootstrap'

interface InputWrapperTabularField {
  name: string
  key: number
  attribute: string
}

interface InputWrapperProps {
  name: string | InputWrapperTabularField
  label?: string
  errors?: any
  children?: React.ReactChild | React.ReactChild[]
  rowAttributes?: object
  colAttributes?: object
}

const InputWrapper: React.FunctionComponent<InputWrapperProps> = (props) => {
  const defaultColAttributes = { md: 6 }

  const errorMessage =
    props.errors ? 
      (typeof props.name === 'string')
        ? props.errors[props.name]?.message ?? null
        : props.errors?.[props.name.name]?.[props.name.key]?.[props.name.attribute]?.message ?? null
      : null

  return (
    <Row {...props.rowAttributes}>
      <Col {...defaultColAttributes} {...props.colAttributes}>
        <Form.Group className='form-group'>
          {typeof props.label !== 'undefined' ? <Form.Label>{props.label}</Form.Label> : ''}
          {props.children}
          {errorMessage !== null && <p className='error'>{errorMessage}</p>}
        </Form.Group>
      </Col>
    </Row>
  )
}

export default InputWrapper

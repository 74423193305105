
import { propsWithBsClassName } from 'react-bootstrap-typeahead/types/utils'
import Page from 'shared/types/Page'
import getPageByPath from './getPageByPath'
import hasAnyRole from './hasAnyRole'

interface getSubMenuProps {
    pages: Page[] | undefined
    curPage: Page | null
    curLink: string
    forceShow?: boolean | undefined
}

const getSubMenu = (props: getSubMenuProps): Page[] => {

    return (props.pages instanceof Array) ? props.pages.filter((obj) => {
        if (props.curPage === null) {
            return false
        }

        if (obj.showInMenu && obj?.parentPath === props.curLink) {

            if (obj.roles !== undefined && !hasAnyRole(obj.roles)) {
                return false;
            }

            if ((props.curPage.parentPath !== undefined) || props.forceShow) {
                const parentPage = getPageByPath(props.curPage.parentPath)

                const showPageInSubMenu = props.forceShow ? true :
                    props.curLink === props.curPage.parentPath ||
                    (parentPage?.parentPath !== undefined &&
                        parentPage.parentPath === props.curLink)
                return (showPageInSubMenu)
            } else {
                return (props.curPage.path === props.curLink)
            }
        }

        return false

    }) : []


}

export default getSubMenu
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup
  .object({
    firstname: yup
      .string()
      .trim()
      .required('First Name is required')
      .min(1, 'First Name must be at least 1 character'),
    lastname: yup
      .string()
      .trim()
      .required('Last Name is required')
      .min(1, 'Last Name must be at least 1 character'),
    email: yup.string().required('Email is required').email('Email is not a valid email address'),
    phone: yup.string().nullable().trim(),
    centers: yup.array(),
    password_api: yup.string(),
  })
  .transform(function (current, original) {
    this.fields.password_api =
      original?.password_api?.length === undefined
        ? yup.string()
        : yup
            .string()
            .min(8, 'New Password must be at least 8 characters')
            .matches(
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
              'New Password must contain at least one uppercase letter, one lowercase letter, one digit and one special symbol.',
            )
            .required('Password is required')
    return current
  })

const resAddUser = yupResolver(schema)

export default resAddUser

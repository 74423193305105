import { AnyAction } from 'redux';

interface IdentityState {
    username: string
    token: string|boolean
}

const noIdentity: IdentityState = {
    'username': '',
    'token': false,
};

const identity = (state = noIdentity, action: AnyAction) => {
    switch (action.type) {
        case 'SET_IDENTITY':
          
            const newState = {
                username: action.username,
                token: action.token,
            }

            return {
                ...state,
                ...newState
            }
            
        default:
            return state
    }
}
  
  export default identity;